import { ComponentConfig, QuestionnaireCategoryOption } from '@st/ui-config'
import { ADDRESS_KEYS, HUMAN_NAME_KEYS } from '@st/tax-folder'
import { JsonMap, isEmpty } from '@st/util/json-value'

type ComponentValidationError =
  | {
      type: 'missing'
      keys: string[]
      label?: string
    }
  | { type: 'selectMinOneCategory'; keys: string[] }

export type QuestionnairePageValidationResult =
  | { ok: true }
  | { ok: false; errors: ComponentValidationError[] }

export function validateQuestionnairePage(
  categoryOptions: QuestionnaireCategoryOption[],
  components: ComponentConfig[],
  inputs: JsonMap
): QuestionnairePageValidationResult {
  const errors: ComponentValidationError[] = []

  for (const component of components) {
    const err = validateComponent(categoryOptions, component, inputs)
    if (err) errors.push(err)
  }

  if (errors.length > 0) {
    console.error('validation failed', errors)
  }

  return errors.length == 0 ? { ok: true } : { ok: false, errors }
}

export function formatValidationErrors(errors: ComponentValidationError[]): string {
  if (errors.length == 1 && errors[0].type == 'selectMinOneCategory') {
    return 'Please select at least one category'
  }
  return `Please complete the required ${errors.length > 1 ? 'questions' : 'question'} to continue`
}

function validateComponent(
  categoryOptions: QuestionnaireCategoryOption[],
  componentConfig: ComponentConfig,
  inputs: JsonMap
): ComponentValidationError | undefined {
  if (!('isUserInputRequired' in componentConfig)) return
  if (!componentConfig.isUserInputRequired) return

  switch (componentConfig.__typename) {
    case 'TextInputConfig':
    case 'MoneyInputConfig':
    case 'DateInputConfig':
    case 'DropdownConfig':
    case 'RadioSelectConfig':
      if (isEmpty(inputs[componentConfig.userInputKey])) {
        return {
          type: 'missing',
          keys: [componentConfig.userInputKey],
          label: componentConfig.label ?? undefined
        }
      }
      break
    case 'MultiSelectConfig':
      if (isEmpty(inputs[componentConfig.userInputKey])) {
        return { type: 'missing', keys: [componentConfig.userInputKey] }
      }
      break
    case 'NumberStepperConfig':
      if (
        isEmpty(inputs[componentConfig.userInputKey]) ||
        inputs[componentConfig.userInputKey] == 0
      ) {
        return { type: 'missing', keys: [componentConfig.userInputKey] }
      }
      break
    case 'YesNoConfig':
      if (
        inputs[componentConfig.userInputKey] !== true &&
        inputs[componentConfig.userInputKey] !== false
      ) {
        return { type: 'missing', keys: [componentConfig.userInputKey] }
      }
      break
    case 'NameInputConfig':
      var keys = HUMAN_NAME_KEYS.map((k) => `${componentConfig.userInputKey}.${k}`)
      var missingKeys = keys.filter((k) => isEmpty(inputs[k]))
      if (missingKeys.length > 0) {
        return { type: 'missing', keys: missingKeys }
      }
      break
    case 'AddressInputConfig':
      var missingKeys = ADDRESS_KEYS.map((k) => `${componentConfig.userInputKey}.${k}`).filter(
        (k) => isEmpty(inputs[k])
      )
      if (missingKeys.length > 0) {
        return { type: 'missing', keys: missingKeys }
      }
      break
    case 'CategorySelectorConfig':
      const categoriesForSection = categoryOptions.filter(
        (c) => c.sectionId === componentConfig.sectionId
      )
      const numberOfSelectedCategories = categoriesForSection.filter(
        (c) => inputs[c.selectedUserInputKey] === true
      ).length

      if (numberOfSelectedCategories === 0) {
        return { type: 'selectMinOneCategory', keys: [] }
      }
      break
  }
}
