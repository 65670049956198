import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { STDocument, STDocumentType, STFolderDownloadState } from '@st/sdk'
import { rootname } from '@st/util'
import { setToggle } from '@st/util/array-set'
import { extname } from '@st/util/file'
import { isNotEmpty } from '@st/util/json-value'
import { direction, sort, SortState, SortTransitions, toggleSort } from '@st/util/sort'
import { match } from 'ts-pattern'
import { getBookmarkSections } from './st-folder-module'

type Status = 'loading' | 'loaded'

export type STDownloadFolderState = {
  status: Status
  folderId: string

  downloadState: STFolderDownloadState | undefined
  selectedDocumentIds: string[]

  download: Download | undefined

  sortState: SortState<DownloadListSortColumn> | undefined

  lastErrors: DownloadError[] | undefined
}

type DownloadFormat = 'zip' | 'pdf'

type Download = {
  format: DownloadFormat
}

export type DownloadError = {
  documentId: string
  filename: string
  error: string
}

export type STDownloadFolderMessage =
  | { type: 'downloadFolder' }
  | { type: 'toggleSelection'; documentId: string }
  | { type: 'toggleSelectAll' }
  | { type: 'downloadStarted'; format: 'zip' | 'pdf' }
  | { type: 'downloadFailed'; errors: DownloadError[] }
  | { type: 'downloadCompleted'; format: 'zip' | 'pdf' }
  | { type: 'refreshFolderDownloadState' }
  | { type: 'toggleSort'; column: DownloadListSortColumn }
  | SDKResponse

export const stDownloadFolderModule = defineModule<
  STDownloadFolderState,
  STDownloadFolderMessage,
  {
    folderId: string
  },
  {
    sdk: SDKMessage // download
  }
>({
  name: 'stDownloadFolder',
  init: ({ folderId }) => {
    return [
      {
        status: 'loading',
        folderId: folderId,
        downloadState: undefined,
        selectedDocumentIds: [],
        download: undefined,
        sortState: undefined,
        lastErrors: undefined
      },
      {
        sdk: {
          type: 'request',
          request: { type: 'folders/getFolderDownloadState', folderId: folderId }
        }
      }
    ]
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'response':
        const op = message.operation
        switch (op.type) {
          case 'folders/getFolderDownloadState':
            return {
              ...state,
              status: match<Status, Status>(state.status)
                .with('loading', () => 'loaded')
                .otherwise(() => 'loaded'),
              downloadState: op.response,
              selectedDocumentIds:
                // we don't want to select all documents if we've already selected some (we don't overwrite the selection)
                state.selectedDocumentIds.length == 0
                  ? op.response.documents.map((doc) => doc.id)
                  : state.selectedDocumentIds
            }
          default:
            return state
        }
      case 'refreshFolderDownloadState':
        return [
          state,
          {
            sdk: {
              type: 'request',
              request: { type: 'folders/getFolderDownloadState', folderId: state.folderId }
            }
          }
        ]
      case 'toggleSelection':
        return {
          ...state,
          selectedDocumentIds: setToggle(state.selectedDocumentIds, message.documentId)
        }
      case 'toggleSelectAll':
        return {
          ...state,
          selectedDocumentIds: selAllDocumentsSelected(state)
            ? []
            : state.downloadState!.documents.map((d) => d.id)
        }
      case 'toggleSort':
        return {
          ...state,
          sortState: toggleSort(state.sortState, message.column, SORT_TRANSITIONS)
        }
      case 'downloadFolder':
        return state
      case 'downloadStarted':
        return { ...state, lastErrors: undefined, download: { format: message.format } }
      case 'downloadCompleted':
        return { ...state, download: undefined }
      case 'downloadFailed':
        return {
          ...state,
          download: undefined,
          lastErrors: message.errors,
          selectedDocumentIds: state.selectedDocumentIds.filter((id) =>
            message.errors.every((e) => e.documentId != id)
          )
        }
      default:
        return state
    }
  }
})

export type DocumentItem = {
  document: STDocument
  documentType: STDocumentType
}

export function selDownloadableDocumentList(state: STDownloadFolderState): DocumentItem[] {
  // we start out with sorting that matches the left bookmark sidebar
  const sections = getBookmarkSections({
    documentTypes: state.downloadState?.documentTypes ?? [],
    documents: state.downloadState?.documents ?? []
  })

  const items = sections.flatMap((s) =>
    s.items.map((d) => ({ document: d, documentType: s.heading }))
  )

  switch (state.sortState?.column) {
    case 'name':
      return sort(
        items,
        direction(state.sortState.direction!, (i) => i.document.name.toLowerCase())
      )
    case 'uploadedAt':
      return sort(
        items,
        direction(state.sortState.direction!, (i) => i.document.uploadedAt ?? '')
      )
    case 'exportedAt':
      return sort(
        items,
        direction(state.sortState.direction!, (i) => i.document.exportedAt ?? '')
      )
    default:
      return items
  }
}

export function selSelectedDocumentsForDownload(state: STDownloadFolderState) {
  const docs = ensureUniqueNames(state.downloadState?.documents ?? [])

  return state.selectedDocumentIds.map((id) => docs.find((doc) => doc.id == id)).filter(isNotEmpty)
}

export function selAllDocumentsSelected(state: STDownloadFolderState) {
  return state.selectedDocumentIds.length == state.downloadState?.documents.length
}

export type DownloadListSortColumn = 'name' | 'uploadedAt' | 'exportedAt'

const SORT_TRANSITIONS: SortTransitions<DownloadListSortColumn> = {
  name: ['asc', 'desc', undefined],
  uploadedAt: ['desc', 'asc', undefined],
  exportedAt: ['desc', 'asc', undefined]
}

/**
 * Given a list of files, returns a new array (does not mutate original).
 * If duplicate names are encountered, it appends `(1)`, `(2)`, etc.
 */
function ensureUniqueNames(files: STDocument[]): STDocument[] {
  const usedNames = new Set<string>()

  return files.map((file) => {
    let candidate = file.name
    let counter = 1

    // If a name is already taken, keep incrementing until it's unique
    while (usedNames.has(candidate)) {
      const e = extname(file.name)
      const base = rootname(file.name)
      candidate = `${base} (${counter})${e}`
      counter++
    }

    usedNames.add(candidate)

    // Return a new XFile object (not mutating the original)
    return { ...file, name: candidate }
  })
}
