import { QuestionTip } from '@st/theme'
import { CheckboxConfig } from '@st/ui-config'
import { ConfigInputProps } from '@util/input-component'

type Props = ConfigInputProps<CheckboxConfig, boolean> & {
  className?: string
  disabled?: boolean
}
export function Checkbox({ value, label, onChange, tip, disabled = false }: Props) {
  return (
    <div className="flex h-8 cursor-pointer flex-row gap-4" onClick={() => onChange(!value)}>
      <input
        disabled={disabled}
        type="checkbox"
        className="size-6 appearance-none rounded-md border border-stone-300 checked:border-transparent checked:bg-blue-600 focus:outline-none"
        checked={value}
        onChange={() => onChange(!value)}
      />
      <span className="text-md font-bold">{label}</span>
      {tip ? (
        <>
          &nbsp;
          <QuestionTip title={tip.title} body={tip.body} />
        </>
      ) : null}
    </div>
  )
}
