import { useAppDeps } from '@features/app-deps-provider'
import { useOrganization } from '@features/organizations'
import { useProcess, useProcessState } from '@st/redux'
import { FolderTag, STOrganizationState } from '@st/sdk'
import {
  Button,
  Dialog,
  DialogButtons,
  EditableTagOption,
  Modal,
  SwatchColorPicker
} from '@st/theme'
import { isEmpty } from '@st/util/json-value'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { stOrganizationModule } from './st-organization-module'
import { OrganizationSettingsScaffold } from './st-organization-settings-scaffold'

type TagOption = {
  id: string
  bg: string
  text: string
}

const TAG_COLOR_OPTIONS: TagOption[] = [
  { id: 'red', bg: 'bg-red-300', text: 'text-red-700' },
  { id: 'orange', bg: 'bg-orange-300', text: 'text-orange-700' },
  { id: 'blue', bg: 'bg-blue-300', text: 'text-blue-700' },
  { id: 'green', bg: 'bg-green-300', text: 'text-green-700' },
  { id: 'purple', bg: 'bg-purple-300', text: 'text-purple-700' },
  { id: 'yellow', bg: 'bg-yellow-300', text: 'text-yellow-900' },
  { id: 'gray', bg: 'bg-gray-300', text: 'text-gray-900' },
  { id: 'pink', bg: 'bg-pink-300', text: 'text-pink-900' }
]

type TagAction = { type: 'edit'; tag: FolderTag } | { type: 'confirmDelete'; tag: FolderTag }

export function OrganizationFolderTagsSettingsPage() {
  const organization = useOrganization()

  useProcess(stOrganizationModule, { organizationId: organization.id })
  const state = useProcessState(stOrganizationModule, (s) => s.state)

  const [action, setAction] = useState<TagAction | undefined>(undefined)

  return (
    <OrganizationSettingsScaffold title="Settings">
      <h2 className="mb-5 text-xl text-gray-800">Tags</h2>
      {state && (
        <TagSettings
          state={state}
          editingTagId={action?.type === 'edit' ? action.tag.id : undefined}
          onAction={setAction}
          onEditingComplete={() => setAction(undefined)}
        />
      )}
      {match(action)
        .with({ type: 'confirmDelete' }, ({ tag }) => (
          <Modal isOpen={true}>
            <DeleteTagDialog tag={tag} onClose={() => setAction(undefined)} />
          </Modal>
        ))
        .otherwise(() => null)}
    </OrganizationSettingsScaffold>
  )
}

function DeleteTagDialog({ tag, onClose }: { tag: FolderTag; onClose: () => void }) {
  const { sdk } = useAppDeps()

  const stOrganization = useProcess(stOrganizationModule)
  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)

  return (
    <Dialog
      title="Confirm delete tag"
      subtitle={`Please confirm whether you would like to delete the tag ${tag.label}`}
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              await sdk.send({
                type: 'organizations/deleteFolderTag',
                organizationId: organizationId,
                tagId: tag.id
              })
              stOrganization.send({ type: 'reload' })
              onClose()
            }}
          >
            Delete
          </Button>
        </DialogButtons>
      }
    />
  )
}

function TagSettings({
  state,
  editingTagId,
  onAction,
  onEditingComplete
}: {
  state: STOrganizationState
  onAction: (action: TagAction) => void
  editingTagId: string | undefined
  onEditingComplete: () => void
}) {
  return (
    <div className="flex w-[600px] flex-col gap-4">
      <div className="flex flex-col gap-2">
        {state.folderTags
          .filter((tag) => !tag.deleted)
          .map((tag) => {
            if (tag.id === editingTagId) {
              return <EditTagForm key={tag.id} tag={tag} onClose={onEditingComplete} />
            }
            return (
              <EditableTagOption
                key={tag.id}
                color={tag.color}
                onClickDelete={() => onAction({ type: 'confirmDelete', tag })}
                onClickEdit={() => onAction({ type: 'edit', tag })}
              >
                {tag.label}
              </EditableTagOption>
            )
          })}
      </div>
      <AddTagForm key={state.folderTags.length} />
    </div>
  )
}

function EditTagForm({ tag, onClose }: { tag: FolderTag; onClose: () => void }) {
  const { sdk } = useAppDeps()

  const stOrganization = useProcess(stOrganizationModule)
  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)

  const [label, setLabel] = useState(tag.label)
  const [colorOption, setColorOption] = useState(TAG_COLOR_OPTIONS.find((o) => o.id === tag.color))

  return (
    <div className="flex flex-row items-center gap-2">
      <input
        type="text"
        placeholder="Tag label"
        className="block w-full rounded-md border-0 py-1.5 pr-10 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
        autoFocus={true}
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />
      <SwatchColorPicker
        options={TAG_COLOR_OPTIONS}
        value={colorOption}
        onChange={setColorOption}
        buildClass={(opt) => opt.bg}
      />
      <Button
        variant="primary"
        disabled={isEmpty(label)}
        onClick={async () => {
          await sdk.send({
            type: 'organizations/editFolderTag',
            organizationId: organizationId,
            tagId: tag.id,
            label: label,
            color: colorOption!.id
          })
          stOrganization.send({ type: 'reload' })
          onClose()
        }}
      >
        Save
      </Button>
    </div>
  )
}

function AddTagForm() {
  const { sdk } = useAppDeps()

  const stOrganization = useProcess(stOrganizationModule)
  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)

  const [label, setLabel] = useState('')
  const [option, setOption] = useState(TAG_COLOR_OPTIONS[0])

  return (
    <div className="flex flex-row items-center gap-2">
      <input
        type="text"
        placeholder="Tag label"
        className="block w-full rounded-md border-0 py-1.5 pr-10 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
        autoFocus={true}
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />
      <SwatchColorPicker
        options={TAG_COLOR_OPTIONS}
        value={option}
        onChange={setOption}
        buildClass={(opt) => opt.bg}
      />
      <Button
        variant="primary"
        disabled={isEmpty(label)}
        onClick={async () => {
          await sdk.send({
            type: 'organizations/createFolderTag',
            organizationId: organizationId,
            label: label,
            color: option.id
          })
          stOrganization.send({ type: 'reload' })
        }}
      >
        Add
      </Button>
    </div>
  )
}
