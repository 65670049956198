import {
  Box,
  Checkbox,
  Column,
  DocumentPage,
  Expand,
  HiddenInput,
  PDFDoc,
  Radio,
  Row,
  Space,
  Text,
  TextInput
} from '@st/pdf'
import { Fragment, ReactNode, createContext, useCallback, useContext } from 'react'
import { setAdd, setRemove } from '../util/array'
import { Pad } from '../util/geom'
import { JSONValue, JsonMap, isNotEmpty, toBool, toStr, toStrArray } from '../util/json-value'
export * from './form-checklist'
export * from './form-table'

const PAGE_PADDING = Pad.all(40)

const HEADING_PADDING = Pad.horizontal(20)

const BLACK = '#000000'
const ORANGE = '#daa00b'
const WHITE = '#ffffff'
export const FORM_BORDER_GREY = '#cdcdcc'

const TEXT_SIZE = 11
const HINT_SIZE = 8

export type LabelPosition = 'left' | 'right'

export type FieldComment = {
  key: string

  /**
   * The author (like username) who edited a field or wrote a comment
   */
  author?: string

  /**
   * The time something was last edited
   */
  time?: string

  /**
   * The body of the meta
   */
  body: string
}

export type ReadHook = (key: string) => JSONValue
export type WriteHook = () => (values: JsonMap) => void

export type ReadFieldDecorationHook = (key: string) => FieldDecoration | undefined
export type ReadFieldCommentHook = (key: string) => FieldComment | undefined

export type FieldDecoration = {
  hint?: string
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
}
export const DEFAULT_FIELD_DECORATION: FieldDecoration = {
  backgroundColor: '#f2f4fd'
}

type ReadWriteContext = {
  useRead: ReadHook
  useFieldDecoration: ReadFieldDecorationHook
  useWrite: WriteHook
  useReadFieldComment: ReadFieldCommentHook
}
const ReadWriteContext = createContext<ReadWriteContext | undefined>(undefined)

export function useReadWriteContext(): ReadWriteContext {
  return (
    useContext(ReadWriteContext) ?? {
      useRead: NO_READ,
      useReadFieldComment: NO_READ_FIELD_META,
      useFieldDecoration: NO_READ_FIELD_DECORATION,
      useWrite: NO_WRITE
    }
  )
}

export function useInputValue<T>(
  inputKey: string,
  cast: (value: unknown) => T
): [T, (value: string | undefined) => void] {
  const { useRead, useWrite } = useReadWriteContext()

  const value = cast(useRead(inputKey))
  const setValues = useWrite()

  const onChange = useCallback(
    (value: string | undefined) => {
      setValues({ [inputKey]: value ?? null })
    },
    [inputKey]
  )

  return [value, onChange]
}

const NO_READ: ReadHook = () => null
const NO_WRITE: WriteHook = () => () => null
const NO_READ_FIELD_DECORATION = () => DEFAULT_FIELD_DECORATION
const NO_READ_FIELD_META = () => undefined
type ReadWriteProviderProps = {
  children: ReactNode
  useRead?: ReadHook
  useWrite?: WriteHook
  useReadFieldComment?: ReadFieldCommentHook
  useFieldDecoration?: ReadFieldDecorationHook
}
export function ReadWriteProvider({
  children,
  useRead = NO_READ,
  useWrite = NO_WRITE,
  useReadFieldComment = NO_READ_FIELD_META,
  useFieldDecoration = NO_READ_FIELD_DECORATION
}: ReadWriteProviderProps) {
  return (
    <ReadWriteContext.Provider
      value={{
        useRead,
        useWrite,
        useReadFieldComment,
        useFieldDecoration
      }}
    >
      {children}
    </ReadWriteContext.Provider>
  )
}

type PDFFormProps = {
  children?: ReactNode
  useRead?: ReadHook
  useWrite?: WriteHook
  zoom?: number
}
export function PDFFormDocument({ zoom, children }: PDFFormProps) {
  return <PDFDoc zoom={zoom}>{children}</PDFDoc>
}

type PDFPageProps = {
  width: number
  height: number
  padding?: Pad
  children: ReactNode
}
export function PDFFormPage({ width, height, padding = PAGE_PADDING, children }: PDFPageProps) {
  return (
    <DocumentPage width={width} height={height}>
      <Box height={Infinity} width={Infinity} padding={padding}>
        <Column>{children}</Column>
      </Box>
    </DocumentPage>
  )
}

export function PageHeading({ children, hasData }: { children: string; hasData?: boolean }) {
  return (
    <Box
      width={Infinity}
      height={26}
      padding={HEADING_PADDING}
      vAlign="center"
      backgroundColor={hasData ? ORANGE : BLACK}
    >
      <Text fontSize={14} fontWeight="bold" color={WHITE}>
        {children}
      </Text>
    </Box>
  )
}

type SectionProps = {
  title?: string
  children: ReactNode
}
export function Section({ title, children }: SectionProps) {
  return (
    <Column mainAxisSize="min">
      {title ? <SectionHeading>{title}</SectionHeading> : null}
      {children}
    </Column>
  )
}

function SectionHeading({ children }: { children: string }) {
  return (
    <Box
      width={Infinity}
      height={20}
      padding={HEADING_PADDING}
      vAlign="center"
      backgroundColor={BLACK}
      borderColor={BLACK}
      borderWidth={1}
    >
      <Text fontSize={12} fontWeight="bold" color={WHITE}>
        {children}
      </Text>
    </Box>
  )
}

export function SectionSubheading({ children }: { children: string }) {
  return (
    <Box
      width={Infinity}
      height={20}
      padding={HEADING_PADDING}
      vAlign="center"
      borderWidth={1}
      borderColor={FORM_BORDER_GREY}
    >
      <Text fontSize={TEXT_SIZE}>{children}</Text>
    </Box>
  )
}

type SectionRowProps = {
  children: ReactNode
  height?: number
  indent?: number
  bullet?: string
  isZeroWidth?: (index: number) => boolean
}
export function SectionRow({
  children,
  height = 20,
  indent = 0,
  bullet,
  isZeroWidth = () => false
}: SectionRowProps) {
  const childrenArray = Array.isArray(children) ? children : [children]
  const elementCount = childrenArray.filter((el, index) => !isZeroWidth(index)).length

  return (
    <Box width={Infinity} height={height} borderWidth={0.5} borderColor={FORM_BORDER_GREY}>
      <Row align="center">
        <Indent indent={indent} bullet={bullet} />
        {childrenArray.map((c, index) => {
          return (
            <Fragment key={index}>
              {c}
              {index < childrenArray.length - 1 && !isZeroWidth(index + 1) ? (
                <Box width={8} height={0} />
              ) : null}
            </Fragment>
          )
        })}
        {elementCount == 1 ? (
          <Expand>
            <Box width={0} height={0} />
          </Expand>
        ) : null}
      </Row>
    </Box>
  )
}

const INDENT_WIDTH = 40
const BULLET_WIDTH = 20
const SECTION_ROW_LEFT_PADDING = 20

function Indent({ indent, bullet }: { indent: number; bullet?: string }) {
  const bulletWidth = bullet ? BULLET_WIDTH : 0
  // remove bullet indent because we will add it back as a box
  const indentAmount = SECTION_ROW_LEFT_PADDING + indent * INDENT_WIDTH - bulletWidth

  return (
    <>
      <Box width={indentAmount} height={20} />
      {bullet ? (
        <Box width={bulletWidth} height={20} vAlign="center" padding={Pad.only({ left: 6 })}>
          {bullet ? <Text fontSize={11}>{bullet}</Text> : null}
        </Box>
      ) : null}
    </>
  )
}

export function Label({
  children,
  fontSize = TEXT_SIZE,
  bold
}: {
  children: string
  fontSize?: number
  bold?: boolean
}) {
  return (
    <Text fontSize={fontSize} fontWeight={bold ? 'bold' : 'normal'}>
      {children}
    </Text>
  )
}

type FormTextInputProps = {
  label?: string
  inputKey: string
  multiline?: boolean
  shouldShowHint?: boolean
  mask?: boolean
  fontSize?: number
}
export function FormTextInput({
  label,
  inputKey,
  multiline,
  shouldShowHint,
  mask,
  fontSize = TEXT_SIZE
}: FormTextInputProps) {
  const { useRead, useWrite, useFieldDecoration } = useReadWriteContext()

  const value = useRead(inputKey)
  const write = useWrite()

  const fieldDecoration = useFieldDecoration(inputKey) ?? DEFAULT_FIELD_DECORATION

  const hint = fieldDecoration.hint

  const textInput = (
    <TextInput
      fontSize={fontSize}
      name={inputKey}
      value={toStr(value)}
      onChange={(v) => write({ [inputKey]: v })}
      multiline={multiline}
      mask={mask}
      {...fieldDecoration}
    />
  )

  if (multiline) {
    return textInput
  }

  const showLabel = isNotEmpty(label)
  const showHint = isNotEmpty(hint) && shouldShowHint
  // this is the space between the hint/label and the text input
  // if there is no label or hint, we don't want extra space on the left
  const showSpace = showLabel || showHint

  return (
    <Row align="center">
      {showLabel ? <Text fontSize={TEXT_SIZE}>{label}</Text> : null}
      {showHint ? (
        <>
          <Space w={6} />
          <Text fontSize={HINT_SIZE} fontStyle="italic">
            {`(${hint})`}
          </Text>
        </>
      ) : null}
      {showSpace ? <Space w={12} /> : null}
      <Expand>{textInput}</Expand>
    </Row>
  )
}

type FormNameInputProps = { userInputKey: string }
export function FormNameInput({ userInputKey }: FormNameInputProps) {
  return (
    <Row align="center">
      <Label>Name</Label>
      <Space w={4} />
      <Expand>
        <WiredTextInput userInputKey={`${userInputKey}.first_name`} />
      </Expand>
      <Space w={1} />
      <Expand>
        <WiredTextInput userInputKey={`${userInputKey}.last_name`} />
      </Expand>
    </Row>
  )

  function WiredTextInput({ userInputKey }: { userInputKey: string }) {
    const { useRead, useWrite, useFieldDecoration } = useReadWriteContext()

    const value = useRead(userInputKey)
    const write = useWrite()
    const fieldDecoration = useFieldDecoration(userInputKey) ?? DEFAULT_FIELD_DECORATION

    return (
      <TextInput
        fontSize={TEXT_SIZE}
        name={userInputKey}
        value={toStr(value)}
        onChange={(v) => write({ [userInputKey]: v })}
        {...fieldDecoration}
      />
    )
  }
}

export type RadioOption = {
  label: string
  value: string | boolean | number
}
type RadioSelectInputProps = {
  label: string
  labelPosition?: LabelPosition
  userInputKey: string
  options: RadioOption[]
}
export function RadioSelectInput({
  label,
  labelPosition = 'left',
  userInputKey,
  options
}: RadioSelectInputProps) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = useRead(userInputKey)
  const write = useWrite()

  return (
    <Row align="center">
      {labelPosition == 'left' ? (
        <>
          <Text fontSize={TEXT_SIZE}>{label}</Text>
          <Space w={12} h={20} />
        </>
      ) : null}

      {options.map((opt, idx) => {
        return (
          <Fragment key={idx}>
            <Radio
              name={userInputKey}
              value={opt.value}
              selected={opt.value == value}
              onChange={(v) => {
                if (!v) return
                write({ [userInputKey]: opt.value })
              }}
              {...getRadioCheckboxStyle(opt.value == value)}
            />
            <Space w={6} />
            <Text fontSize={TEXT_SIZE}>{opt.label}</Text>
            <Space w={6} />
          </Fragment>
        )
      })}

      {labelPosition == 'right' ? (
        <>
          <Space w={12} h={20} />
          <Text fontSize={TEXT_SIZE}>{label}</Text>
        </>
      ) : null}
    </Row>
  )
}

export type MultiSelectOption = {
  label: string
  value: string
}
type MultiSelectInputProps = {
  label?: string
  userInputKey: string
  options: MultiSelectOption[]
}
export function MultiSelectInput({ label, userInputKey, options }: MultiSelectInputProps) {
  const { useRead, useWrite } = useReadWriteContext()

  const selectedValues = toStrArray(useRead(userInputKey))
  const write = useWrite()

  /**
   * Example options
   * [
   *   { label: '1099-G', value: '1099_g' },
   *   { label: '1099-NEC', value: '1099_nec' },
   *   { label: '1099-MISC', value: '1099_misc' },
   *   { label: '1099-K', value: '1099_k' }
   * ]
   *
   * Example value:
   * [ '1099_k', '1099_nec' ]
   */

  return (
    <Row align="center">
      {/* The page level component reads off the user input keys to detect what pages have data filled in */}
      {/* For historical reasons, multi-select components write an array of values rather than distinct user inputs */}
      {/* Meanwhile the PDF has distinct user inputs for each checkbox in the multi-select */}
      {/* This mismatch causes multi-select inputs to not be detected */}
      {/* Later, we should separate to independent inputs and remove this hack */}
      <HiddenInput name={userInputKey} value="" />

      {isNotEmpty(label) ? <Text fontSize={TEXT_SIZE}>{label}</Text> : null}
      {isNotEmpty(label) ? <Space w={12} h={20} /> : null}
      {options.map((opt, idx) => {
        // Because a form needs a unique name, we need construct a full name
        const name = `${userInputKey}.${opt.value}`
        return (
          <Fragment key={idx}>
            <Checkbox
              name={name}
              value={selectedValues.includes(opt.value)}
              onChange={(v) => {
                write({
                  [userInputKey]: v
                    ? setAdd(selectedValues, opt.value)
                    : setRemove(selectedValues, opt.value)
                })
              }}
            />
            <Space w={6} />
            <Text fontSize={TEXT_SIZE}>{opt.label}</Text>
            <Space w={6} />
          </Fragment>
        )
      })}
    </Row>
  )
}

type YesNoRadioProps = {
  label: string
  labelPosition?: 'left' | 'right'
  userInputKey: string
}
export function YesNoRadio({ label, labelPosition, userInputKey }: YesNoRadioProps) {
  const options: RadioOption[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]

  return (
    <RadioSelectInput
      label={label}
      labelPosition={labelPosition}
      userInputKey={userInputKey}
      options={options}
    />
  )
}

type CheckboxInputProps = {
  label: string
  userInputKey: string
}
export function CheckboxInput({ label, userInputKey }: CheckboxInputProps) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = useRead(userInputKey)
  const write = useWrite()

  return (
    <Row align="center">
      <Checkbox
        name={userInputKey}
        value={toBool(value)}
        onChange={(v) => {
          write({ [userInputKey]: v })
        }}
        {...getRadioCheckboxStyle(toBool(value))}
      />
      <Space w={8} h={20} />
      <Text fontSize={TEXT_SIZE}>{label}</Text>
    </Row>
  )
}

type SelectionStyle = {
  backgroundColor?: string
}
function getRadioCheckboxStyle(selected: boolean): SelectionStyle {
  return selected ? { backgroundColor: '#daa00b' } : { backgroundColor: '#ffffff' }
}
