type RGB = { r: number; g: number; b: number }
export function hexToRgb(hex: string): RGB | undefined {
  hex = hex.replace('#', '').toLowerCase()

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  if (isNaN(r) || isNaN(g) || isNaN(b)) throw `Invalid hex color ${hex}`

  return { r, g, b }
}
