import { useState } from 'react'
import { match } from 'ts-pattern'
import { DeleteIcon } from '@components/icons'
import { selClientComment, stFolderModule } from '@features/st-folder-viewer/st-folder-module'
import { NoteWidgetConfig } from '@st/ui-config'
import { useDebouncedCallback } from '@st/react-util/use-debounced-callback'
import { useProcess, useProcessState } from '@st/redux'
import { Button, Dialog, DialogButtons, Modal } from '@st/theme'
import { isNotEmpty } from '@st/util/json-value'
import { AddNoteIcon } from '@theme/icons'
import { ButtonGroup } from '@theme'

export function WizardNoteComposer(config: NoteWidgetConfig) {
  const commentKey = getCommentKey(config.userInputKey)
  const stFolder = useProcess(stFolderModule)
  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)

  const originalClientComment = useProcessState(stFolderModule, (s) =>
    selClientComment(s, commentKey)
  )

  const [comment, setComment] = useState(originalClientComment)
  const [open, setOpen] = useState<boolean>(isNotEmpty(originalClientComment))
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState<undefined | 'confirmDelete'>()

  // Debounce changes to avoid sending request on every keystroke
  const flushChanges = useDebouncedCallback((value: string) => {
    stFolder.send({
      type: 'request',
      request: { type: 'folders/setClientComment', folderId, commentKey, commentBody: value }
    })
  }, 1000)

  async function onConfirmDelete() {
    setComment('')
    setConfirmDeleteDialog(undefined)
    setOpen(false)
    stFolder.send({
      type: 'request',
      request: { type: 'folders/setClientComment', folderId, commentKey, commentBody: '' }
    })
  }

  return (
    <div className="flex flex-col">
      {open ? (
        <div className="relative">
          {/* Top “toolbar” bar */}
          <div className="absolute left-5 right-5 top-3 flex h-10 flex-row items-center justify-between">
            <div className="font-lg flex flex-row items-center gap-3 text-black">
              <AddNoteIcon /> Note
            </div>
            <ButtonGroup>
              <Button variant="subtle" onClick={() => setConfirmDeleteDialog('confirmDelete')}>
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </div>

          {/* The actual text area */}
          <textarea
            autoFocus
            rows={5}
            className="w-full rounded-md border border-gray-300 pb-3 pt-12 text-base focus:border-blue-500 focus:outline-none"
            value={comment ?? ''}
            onChange={(e) => {
              setComment(e.target.value)
              flushChanges(e.target.value)
            }}
          />
        </div>
      ) : (
        <button
          className="flex h-10 flex-row items-center gap-2 self-end rounded-md border border-gray-200 bg-white px-3.5 text-base hover:border-blue-500"
          onClick={() => setOpen(true)}
        >
          <AddNoteIcon className="mr-2 text-blue-500" />
          Add a note
        </button>
      )}

      {match(confirmDeleteDialog)
        .with('confirmDelete', () => (
          <Modal isOpen={true}>
            <Dialog
              title="Delete note"
              subtitle="Please confirm if you would like to delete this note"
              buttons={
                <DialogButtons>
                  <Button variant="subtle" onClick={() => setConfirmDeleteDialog(undefined)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={onConfirmDelete}>
                    Delete
                  </Button>
                </DialogButtons>
              }
            />
          </Modal>
        ))
        .otherwise(() => null)}
    </div>
  )
}

function getCommentKey(userInputKey: string) {
  if (userInputKey.startsWith('questionnaire.notes.client.')) {
    return userInputKey.substring('questionnaire.notes.client.'.length)
  }
  return userInputKey
}
