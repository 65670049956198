'use client'

import { PaperClipIcon } from '@st/theme/src/icons/16x16'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { CheckCircle, EyeIcon, TrashIcon } from './icons'
import { Tip } from './Tip'

export function QuestionnaireDashboardContainer(props: {
  children?: ReactNode
  branding?: ReactNode
  menu?: ReactNode
}) {
  return (
    <div className="flex h-svh max-h-svh w-full flex-col">
      <header className="sticky top-0 flex h-12 w-full flex-row items-center justify-between border-b bg-white px-14">
        {props.branding}
        {props.menu}
      </header>

      <main className="h-full flex-1 overflow-y-scroll bg-gray-100 px-4 py-4">
        <div className="m-auto flex max-w-4xl flex-col items-stretch gap-8">{props.children}</div>
      </main>
    </div>
  )
}

export function QuestionnairePageContainer(props: {
  header?: ReactNode
  banner?: ReactNode
  footer?: ReactNode
  children?: ReactNode
  stickyNote?: ReactNode
}) {
  return (
    <div className="flex h-svh max-h-svh w-full flex-col">
      {props.header}

      {props.banner}

      <main className="relative h-full flex-1 overflow-y-scroll bg-gray-100 px-4 py-12">
        {props.stickyNote}
        <div className="m-auto flex h-full max-w-2xl flex-col items-stretch gap-8">
          {props.children}
        </div>
      </main>

      {props.footer}
    </div>
  )
}

export function QuestionnaireHeader(props: {
  leading?: ReactNode
  trailing?: ReactNode
  children: ReactNode
}) {
  return (
    <header className="sticky top-0 flex w-full flex-row items-center justify-between border-b bg-white px-7 py-2">
      <div className="flex flex-1 justify-start">{props.leading}</div>

      <div className="flex flex-row items-center justify-between gap-3 sm:gap-12">
        {props.children}
      </div>

      <div className="flex flex-1 justify-end">{props.trailing}</div>
    </header>
  )
}

export function QuestionnaireProgressBar(props: { value: number }) {
  return (
    <div className="h-full flex-1 overflow-hidden rounded-md bg-gray-200">
      <div
        style={{ width: (props.value * 100).toString() + '%' }}
        className="h-full rounded-md bg-blue-500 transition-[width] duration-1000 ease-in-out"
      />
    </div>
  )
}

export function QuestionnaireFooter(props: { children: ReactNode }) {
  return (
    <footer className="sticky bottom-0 flex w-full flex-row items-center justify-between gap-2 border-b bg-white px-7 py-2">
      {props.children}
    </footer>
  )
}

export function QuestionnaireFilesList({ children }: { children: ReactNode }) {
  return <div className="flex flex-col divide-y divide-gray-200">{children}</div>
}

export function QuestionnaireFile({
  children,
  onClickPreview,
  onClickDelete
}: {
  children: ReactNode
  onClickPreview?: () => void
  onClickDelete?: () => void
}) {
  return (
    <div className="group flex flex-row items-center gap-1 py-2 text-base font-semibold text-gray-900">
      <PaperClipIcon />
      <div className="flex flex-1 flex-row items-center [font-variant-ligatures:none]">
        {children}
      </div>

      {onClickPreview && (
        <Tip title="Preview document">
          <button className="ml-3" onClick={onClickPreview}>
            <EyeIcon className="size-4" />
          </button>
        </Tip>
      )}

      {onClickDelete && (
        <Tip title="Delete document">
          <button className="ml-3" onClick={onClickDelete}>
            <TrashIcon className="size-4" />
          </button>
        </Tip>
      )}
    </div>
  )
}

export function QuestionnaireChecklist({ children }: { children?: ReactNode }) {
  // Instead of <table>, we just wrap rows in a <div> with dividers
  return <div className="w-full min-w-0 divide-y divide-gray-200">{children}</div>
}

export function QuestionnaireChecklistRow(props: {
  title?: string
  note?: string
  completed?: boolean
}) {
  return (
    <div className="grid grid-cols-1 items-start py-2 md:grid-cols-[20rem,1fr]">
      <div className="flex flex-row items-center gap-2 whitespace-nowrap pl-12 pr-2">
        <Bullet />
        <span className="text-base font-medium text-gray-900">{props.title}</span>
      </div>

      {/* pl-8 ensures that on mobile screens the text is aligned with the title */}
      <div className="pl-12 pr-3 text-base font-normal text-gray-700">
        <div className="truncate md:whitespace-nowrap">{props.note}</div>
      </div>
    </div>
  )
}

function Bullet() {
  return (
    <svg viewBox="0 0 8 8" className="size-1.5" fill="currentColor">
      <circle cx="4" cy="4" r="4" />
    </svg>
  )
}

export function QuestionnaireCard({ children }: { children: ReactNode }) {
  return (
    <section className="flex flex-col items-stretch gap-4 rounded-md p-6 shadow-md">
      {children}
    </section>
  )
}

export function QuestionnaireCardHeader(props: {
  step: number
  title: string
  subtitle: string
  right?: ReactNode
}) {
  return (
    <div className="flex flex-row">
      <div className="flex flex-1 flex-row gap-4">
        <div className="text-md inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-blue-200 font-bold text-blue-500">
          {props.step}
        </div>
        <div className="flex flex-col">
          <h2 className="text-xl">{props.title}</h2>
          <p className="mr-4 text-base text-gray-700">{props.subtitle}</p>
        </div>
      </div>
      {props.right}
    </div>
  )
}

export function QuestionnaireSectionTab(props: {
  icon: string
  caption: string
  selected?: boolean
  onClick?: () => void
}) {
  return (
    <button
      className={clsx('flex flex-col items-center', props.selected && 'text-blue-500')}
      onClick={props.onClick}
    >
      <QuestiononaireSectionIcon sectionId={props.icon} />
      <span className="text-center text-sm font-semibold uppercase">{props.caption}</span>
    </button>
  )
}

export function QuestiononaireSectionIcon({ sectionId }: { sectionId: string }) {
  switch (sectionId) {
    case 'basic-info':
      return <BasicInfoIcon />
    case 'income':
      return <IncomeIcon />
    case 'deductions':
      return <DeductionsIcon />
  }
  return <></>
}

function BasicInfoIcon() {
  return (
    <svg className="h-8 w-8" viewBox="0 0 40 40" fill="none">
      <path
        d="M35 6C36.1046 6 37 6.89543 37 8V32C37 33.1046 36.1046 34 35 34H5C3.89543 34 3 33.1046 3 32V8C3 6.89543 3.89543 6 5 6H35Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M16 16C16 17.6569 14.6569 19 13 19C11.3431 19 10 17.6569 10 16C10 14.3431 11.3431 13 13 13C14.6569 13 16 14.3431 16 16ZM8 22.5C8 21.6716 8.67157 21 9.5 21H16.5C17.3284 21 18 21.6716 18 22.5V24C18 24 18 27.5 13 27.5C8 27.5 8 24 8 24V22.5Z"
        stroke="currentColor"
      />
      <rect x="22" y="15" width="10" height="1" rx="0.5" fill="currentColor" />
      <rect x="22" y="20" width="10" height="1" rx="0.5" fill="currentColor" />
      <rect x="22" y="25" width="10" height="1" rx="0.5" fill="currentColor" />
    </svg>
  )
}

function IncomeIcon() {
  return (
    <svg className="h-8 w-8" viewBox="0 0 40 40" fill="none">
      <g clipPath="url(#clip0_948_847)">
        <path
          d="M1.12367 13.3607L34.5332 7.67034C34.8356 7.61882 35.1216 7.82155 35.1731 8.12402L38.0065 24.7594C38.058 25.0619 37.8553 25.3479 37.5528 25.3994L4.14333 31.0898C3.84087 31.1413 3.5549 30.9386 3.50338 30.6361L0.669989 14.0007C0.618472 13.6982 0.821204 13.4123 1.12367 13.3607Z"
          stroke="currentColor"
        />
        <path
          d="M4.39294 15.8877H38.2836C38.3142 15.8877 38.3383 15.9117 38.3383 15.9424V32.8174C38.3383 32.8481 38.3142 32.8721 38.2836 32.8721H4.39294C4.36227 32.8721 4.33826 32.8481 4.33826 32.8174V15.9424C4.33826 15.9117 4.36227 15.8877 4.39294 15.8877Z"
          fill="white"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M29 24.5C29 28.9183 25.4183 32.5 21 32.5C16.5817 32.5 13 28.9183 13 24.5C13 20.0817 16.5817 16.5 21 16.5C25.4183 16.5 29 20.0817 29 24.5Z"
          stroke="currentColor"
        />
        <path
          d="M8.68201 25.4346C9.26448 25.4346 9.73669 24.9624 9.73669 24.3799C9.73669 23.7974 9.26448 23.3252 8.68201 23.3252C8.09954 23.3252 7.62732 23.7974 7.62732 24.3799C7.62732 24.9624 8.09954 25.4346 8.68201 25.4346ZM33.9945 23.3252C33.412 23.3252 32.9398 23.7974 32.9398 24.3799C32.9398 24.9624 33.412 25.4346 33.9945 25.4346C34.577 25.4346 35.0492 24.9624 35.0492 24.3799C35.0492 23.7974 34.577 23.3252 33.9945 23.3252Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5 19.4933V16H20.5V19.5476C20.0306 19.6492 19.5701 19.8645 19.2053 20.2208C18.7678 20.6481 18.5 21.2482 18.5 22.0003C18.5 22.9996 18.8312 23.6641 19.4055 24.0894C19.9138 24.4659 20.5665 24.6106 21.0945 24.7277L21.1415 24.7382C21.7306 24.8691 22.179 24.9771 22.4992 25.2144C22.7687 25.414 23 25.7495 23 26.5003C23 27.0859 22.7559 27.4606 22.4113 27.6998C22.0464 27.9532 21.538 28.0723 21.0257 28.0459C20.5245 28.0201 20.0066 27.8534 19.5879 27.5742C19.3581 27.421 19.0477 27.4831 18.8945 27.7129C18.7413 27.9427 18.8034 28.2531 19.0332 28.4063C19.4725 28.6992 19.982 28.8976 20.5 28.99V32.9902H21.5V29.0341C22.0194 28.9865 22.5434 28.8255 22.9816 28.5213C23.5894 28.0993 24 27.4208 24 26.5003C24 25.5009 23.6688 24.8363 23.0945 24.4109C22.5861 24.0342 21.9334 23.8895 21.4053 23.7724L21.3585 23.762C20.7693 23.6311 20.321 23.523 20.0007 23.2858C19.7313 23.0863 19.5 22.7509 19.5 22.0003C19.5 21.5025 19.6697 21.1651 19.9041 20.9361C20.1475 20.6984 20.4931 20.5473 20.8786 20.4959C21.2649 20.4444 21.6572 20.498 21.966 20.6312C22.2766 20.7652 22.4586 20.9572 22.5257 21.1584C22.613 21.4204 22.8961 21.5619 23.1581 21.4746C23.4201 21.3873 23.5617 21.1042 23.4743 20.8422C23.2914 20.2932 22.8484 19.9227 22.3621 19.713C22.095 19.5978 21.8014 19.5242 21.5 19.4933Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_948_847">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

function DeductionsIcon() {
  return (
    <svg className="h-8 w-8" viewBox="0 0 40 40" fill="none">
      <path
        d="M37 6C38.1046 6 39 6.89543 39 8V27C39 28.1046 38.1046 29 37 29H8C6.89543 29 6 28.1046 6 27V8C6 6.89543 6.89543 6 8 6H37Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M33.5 23H28.5C28.2239 23 28 23.2239 28 23.5C28 23.7761 28.2239 24 28.5 24H33.5C33.7761 24 34 23.7761 34 23.5C34 23.2239 33.7761 23 33.5 23Z"
        fill="currentColor"
      />
      <path d="M40 11H5V12H40V11Z" fill="currentColor" />
      <circle cx="9.5" cy="25.5" r="9" fill="white" stroke="currentColor" />
      <path d="M9.5 21.5V30.5" stroke="currentColor" strokeLinecap="round" />
      <path d="M6 27L9.5 30.5" stroke="currentColor" strokeLinecap="round" />
      <path d="M13 27L9.5 30.5" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}
