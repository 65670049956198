import { Channel, Socket } from 'phoenix'
import { InMemoryStorage } from '@st/util/key-value-storage'

type RemoteSocketEvent = {
  type: 'disconnect'
  code: number
  wasClean: boolean
  /**
   * True if we called .disconnect() ourselves. False if it was external (server, Fly.io, etc).
   */
  userInitiated: boolean
}

type InnerSocket = Socket

declare module 'phoenix' {
  interface Socket {
    /**
     * Phoenix sets this to `true` if `.disconnect()` was called intentionally,
     * so no reconnect is attempted on code=1000.
     * By default, `false` if a server/proxy closed the connection externally.
     */
    closeWasClean: boolean

    /**
     * The Timer used by Phoenix’s internal reconnect logic (exponential backoff).
     * We override it if we want to force reconnect for code=1000.
     */
    reconnectTimer: Timer
  }
}

type RemoteSocketOpts = {
  endpoint: string
  onEvent?: (event: RemoteSocketEvent) => void
}

/**
 * A higher-level wrapper around a Phoenix Socket to:
 *  - Distinguish user-initiated .disconnect() from external code=1000 closes.
 *  - Encapsulate re-joining channels if we want (shown as example).
 *  - Force Phoenix to reconnect even if code=1000 wasn't from us.
 */
export class RemoteSocket {
  private innerSocket: InnerSocket

  /** If `true`, we explicitly called .disconnect() from this client */
  private userInitiatedDisconnect = false

  private topics: Record<string, number> = {}
  private channels: Record<string, Channel> = {}

  constructor(opts: RemoteSocketOpts) {
    // Create the underlying Phoenix socket
    this.innerSocket = new Socket(opts.endpoint, {
      sessionStorage: new InMemoryStorage({ syncTabs: false }),
      debug: true,
      logger: (kind, message, data) => {
        console.log('[phoenix]', kind, filterLoggerData(message), filterLoggerData(data))

        // We watch for `close` events here to decide if we force Phoenix to reconnect or not
        if (kind === 'transport' && message === 'close') {
          const userInitiated = this.userInitiatedDisconnect
          const eventCode = data.code
          const wasClean = data.wasClean

          // Let the user’s external callback know
          opts.onEvent?.({
            type: 'disconnect',
            code: eventCode,
            wasClean,
            userInitiated
          })

          // If the code=1000 was NOT user-initiated, we want to reconnect anyway:
          // By default, Phoenix won't reconnect for code=1000 because it’s considered “clean.”
          // So we forcibly tell Phoenix “that wasn’t clean after all,” which triggers the usual reconnect.
          //
          // This is because sometimes "clean" is actually a lie. Fly.io and other hosts may drop websocket connections
          // with a status code of 1000.
          // This results in the phoenix socket not reconnecting, which is not what we want.
          if (eventCode === 1000 && !userInitiated) {
            console.warn(
              '[RemoteSocket] Normal-close (non-browser) with code=1000 which is not expected. Trying to reconnect...'
            )
            // Tell Phoenix that close “wasn’t clean”
            this.innerSocket.closeWasClean = false

            // Fire the standard reconnect timer
            // (Equivalent to what Phoenix does if code != 1000)
            this.innerSocket.reconnectTimer.scheduleTimeout()
          }

          // After any close event, reset our userInitiatedDisconnect to false
          this.userInitiatedDisconnect = false
        }
      }
    }) as InnerSocket
  }

  /**
   * Open or re-open the underlying socket (normally after logging in).
   */
  connect({ token }: { token: string }) {
    this.userInitiatedDisconnect = false

    this.innerSocket.connect({ token })
  }

  /**
   * Call this if you want to intentionally close from the client side.
   * We mark `userInitiatedDisconnect = true` so that if we see code=1000,
   * we _don’t_ override Phoenix’s default “clean close” behavior.
   */
  disconnect() {
    this.userInitiatedDisconnect = true
    // This calls Phoenix’s “close code=1000” by default
    this.innerSocket.disconnect()
    this.channels = {}
  }

  subscribe(topic: string) {
    this.topics[topic] = (this.topics[topic] ?? 0) + 1
    this.syncChannelStatesWithTopics()
    return () => {
      this.topics[topic]--
      if (this.topics[topic] <= 0) {
        delete this.topics[topic]
      }
      this.syncChannelStatesWithTopics()
    }
  }

  /**
   * Hook up event handlers on a channel
   */
  on<T = any>(topic: string, eventName: string, callback: (msg: T) => void): () => void {
    if (!(topic in this.channels)) {
      throw new Error(`No channel named '${topic}' is currently joined`)
    }
    const channel = this.channels[topic]
    const ref = channel.on(eventName, callback)
    return () => {
      channel.off(eventName, ref)
    }
  }

  // Reconciles our desired topics with what’s actually joined in Phoenix.
  private syncChannelStatesWithTopics() {
    const currentStates = getChannelStatesByTopic(Object.values(this.channels))
    const ops = getChannelOps(currentStates, Object.keys(this.topics))

    for (const op of ops) {
      switch (op.type) {
        case 'join': {
          const channel = this.innerSocket.channel(op.topic)
          this.channels[channel.topic] = channel
          channel.join()
          console.log('[RemoteSocket] channel.join', channel.topic)
          break
        }
        case 'leave': {
          const ch = this.channels[op.topic]
          ch.leave()
          delete this.channels[op.topic]
          console.log('[RemoteSocket] channel.leave', op.topic)
          break
        }
      }
    }
  }
}

/** Helper: Map topic -> current channel state (joined, joining, etc.). */
type TopicToChannelState = Record<string, Channel['state']>

function getChannelStatesByTopic(channels: Channel[]): TopicToChannelState {
  const states: TopicToChannelState = {}
  for (const c of channels) {
    states[c.topic] = c.state
  }
  return states
}

/** We produce a list of "join" or "leave" ops to reconcile current vs. desired. */
type ChannelOp = { type: 'join'; topic: string } | { type: 'leave'; topic: string }

function getChannelOps(channelStates: TopicToChannelState, desiredTopics: string[]): ChannelOp[] {
  const ops: ChannelOp[] = []

  // For each desired topic, if we are not already joined/joining, do a 'join'
  for (const topic of desiredTopics) {
    const currentState = channelStates[topic] ?? 'closed'
    switch (currentState) {
      case 'closed':
      case 'errored':
      case 'leaving':
        ops.push({ type: 'join', topic })
        break
      case 'joined':
      case 'joining':
        // Already joining or joined -> no-op
        break
    }
  }
  // For any channel that’s joined but is NOT desired, do a 'leave'
  for (const [topic, state] of Object.entries(channelStates)) {
    if ((state === 'joined' || state === 'joining') && !desiredTopics.includes(topic)) {
      ops.push({ type: 'leave', topic })
    }
  }
  return ops
}

/** A helper to redact potentially sensitive tokens or large data from logs. */
function filterLoggerData(data: any): any {
  if (typeof data === 'string' && (data.includes('ws://') || data.includes('token='))) {
    return truncateToken(data)
  } else if (typeof data === 'object' && data !== null && 'state' in data) {
    return { ...data, state: '<truncated>' }
  } else if (typeof data === 'object' && data !== null && 'response' in data) {
    return { ...data, response: '<truncated>' }
  } else {
    return data
  }
}

function truncateToken(url: string) {
  return url.replace(/(token=)([A-Za-z0-9-_]{8})[A-Za-z0-9-_]+/, '$1$2...')
}
