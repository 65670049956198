import { SDKRequest, SDKResponse, SDKScheduledRun, SDKScheduleIn } from '@features/sdk-module'
import { PlatformMessage } from '@features/st-pdf-viewer/platform-module'
import { formationFieldDecoration } from '@st/formation'
import { FieldComment, FieldDecoration } from '@st/pdf'
import { defineModule, defineTask, SendProtocol } from '@st/redux'
import {
  Entities,
  FieldHint,
  MoveChecklistItem,
  Operation,
  ScopedFolderMembership,
  SDKOperation,
  SetClientComment,
  SetFolderInputs,
  SetFolderInputsOperation,
  STCategory,
  STChecklistItem,
  STDocument,
  STDocumentType,
  STMissingReason,
  STOpenFolderState,
  SubmitQuestionnaire,
  UnlockQuestionnaire,
  DeleteDocument
} from '@st/sdk'
import { UNCATEGORIZED_DOCUMENT_TYPE_ID } from '@st/ui-config'
import { findLast } from '@st/util/array'
import { formatEntities } from '@st/util/entities'
import { isNotEmpty, JsonMap } from '@st/util/json-value'
import { Section } from '@st/util/section'
import { asc, sort } from '@st/util/sort'
import { formatISO8601DateTime } from '@st/util/time'
import { applyPatch } from 'fast-json-patch'

export type STFolderModuleState = {
  currentUserId: string
  folderState: STOpenFolderState | undefined
  folderStateVsn: number
  selectedDocId: string | undefined
  queue: OperationQueue<SDKOperation>
}

/**
 * The UI collapses all the possible roles into 2: organization_member and client
 * This is because there are only 2 variations of the UX based on one of these
 */
export type FolderUIRole =
  /**
   * A member of the organization (an accountant)
   */
  | 'organization_member'
  /**
   * The client who is meant to submit their info (in the database it can be a client or collaborator)
   */
  | 'client'

type STFolderSend = {
  sdk: SDKRequest | SDKScheduleIn
  platform: PlatformMessage
}

type STFolderInit = {
  currentUserId: string
}

export type STFolderMessage =
  | { type: 'stateLoad'; vsn: number; state: STOpenFolderState }
  | { type: 'stateDiff'; fromVsn: number; toVsn: number; diff: any }
  | { type: 'docSelected'; docId: string }
  | { type: 'request'; request: FolderRequest }
  | SDKResponse
  | SDKScheduledRun<string>

/**
 * The subset of requests that are relevant to an open folder
 */
export type FolderRequest =
  | SetFolderInputs
  | SubmitQuestionnaire
  | UnlockQuestionnaire
  | SetClientComment
  | MoveChecklistItem
  | DeleteDocument

export const stFolderModule = defineModule<
  STFolderModuleState,
  STFolderMessage,
  STFolderInit,
  STFolderSend
>({
  name: 'stFolder',
  init: ({ currentUserId }) => {
    return {
      currentUserId: currentUserId,
      folderState: undefined,
      selectedDocId: undefined,
      folderStateVsn: -1,
      queue: { lastId: '0', items: [] }
    }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'stateLoad': {
        if (!state.folderState) {
          // first load
          const sections = getBookmarkSections(message.state)
          return {
            ...state,
            folderState: message.state,
            folderStateVsn: message.vsn,
            selectedDocId: sections.length == 0 ? undefined : sections[0].items[0].id
          }
        } else {
          return { ...state, folderStateVsn: message.vsn, folderState: message.state }
        }
      }
      case 'stateDiff':
        const nextFolderState: STOpenFolderState = applyPatch(
          state.folderState,
          message.diff,
          false, // don't validate patch operation
          false // don't mutate document (redux pattern so we must return new data)
        ).newDocument as any

        return {
          ...state,
          folderState: nextFolderState,
          folderStateVsn: message.toVsn
        }
      case 'docSelected':
        return { ...state, selectedDocId: message.docId }
      case 'request':
        const req = message.request

        if (req.type == 'folders/setFolderInputs') {
          const newQueue = addToQueue(state.queue, {
            status: 'queued',
            id: nextQueueItemId(state.queue),
            op: { request: message.request }
          })

          const mergedQueue = mergeQueuedOperations(
            newQueue,
            (item): item is QueuedOperationQueueItem<SetFolderInputsOperation> =>
              item.op.request.type == 'folders/setFolderInputs',
            (items) => {
              const mergedItem: QueuedOperationQueueItem<SetFolderInputsOperation> = {
                status: 'queued',
                id: items.length == 0 ? nextQueueItemId(state.queue) : items[0].id,
                op: { request: mergeRequests(items.map((item) => item.op.request)) }
              }
              return mergedItem
            }
          )

          return processQueuedRequests({ ...state, queue: mergedQueue })
        } else if (req.type == 'folders/submitQuestionnaire') {
          return processQueuedRequests({
            ...state,
            queue: addToQueue(state.queue, {
              status: 'queued',
              id: nextQueueItemId(state.queue),
              op: { request: message.request }
            })
          })
        }
        return processQueuedRequests({
          ...state,
          queue: addToQueue(state.queue, {
            status: 'queued',
            id: nextQueueItemId(state.queue),
            op: { request: message.request }
          })
        })
      case 'response':
        const newResponseState = resolveQueuedRequest(state, message)
        if (message.operation.type == 'folders/unlockQuestionnaire') {
          return [
            newResponseState,
            { platform: { type: 'showSnackbar', message: 'Unlocked questionnaire' } }
          ]
        } else {
          return newResponseState
        }
      case 'scheduledRun':
        return runScheduledRequest(state, message.data)
      default:
        return state
    }
  }
})

function runScheduledRequest(
  state: STFolderModuleState,
  itemId: string
): [STFolderModuleState, SendProtocol<STFolderSend>] {
  const item = state.queue.items.find((el) => el.id == itemId)
  if (!item) throw `No item found with id ${itemId}`
  if (item.status != 'queued') throw `Item with id ${itemId} should have status queued`

  const itemsToRun: RunningOperationQueueItem<SDKOperation>[] = []
  const newItems: OperationQueueItem<SDKOperation>[] = state.queue.items.map((item) => {
    if (item.id != itemId) {
      return item
    }

    const newItem: RunningOperationQueueItem<SDKOperation> = {
      id: item.id,
      status: 'running',
      op: item.op
    }
    itemsToRun.push(newItem)
    return newItem
  })

  const requests: Array<SDKRequest | SDKScheduleIn> = itemsToRun.map((item) => {
    return { type: 'request', requestId: item.id, request: item.op.request }
  })

  return [{ ...state, queue: { ...state.queue, items: newItems } }, { sdk: requests }]
}

function processQueuedRequests(
  state: STFolderModuleState
): STFolderModuleState | [STFolderModuleState, SendProtocol<STFolderSend>] {
  const itemsToSave = state.queue.items.filter((item) => item.status == 'queued')
  if (itemsToSave.length == 0) {
    // nothing to save
    return state
  }

  const itemsToSchedule: QueuedOperationQueueItem<SDKOperation>[] = []
  const newItems: OperationQueueItem<SDKOperation>[] = state.queue.items.map((item) => {
    if (item.status != 'queued') {
      return item
    }
    itemsToSchedule.push(item)
    return item
  })

  const requests: Array<SDKRequest | SDKScheduleIn> = itemsToSchedule.map((item) => {
    return { type: 'scheduleIn', delay: 2000, scheduleId: item.id, data: item.id }
  })

  return [{ ...state, queue: { ...state.queue, items: newItems } }, { sdk: requests }]
}

const MAX_COMPLETED_QUEUE_ITEMS = 5

function resolveQueuedRequest(
  state: STFolderModuleState,
  message: SDKResponse
): STFolderModuleState {
  if (!message.requestId) {
    return state
  }

  const updatedItems: OperationQueueItem<SDKOperation>[] = state.queue.items.map((item) => {
    if (item.id == message.requestId) {
      return {
        status: 'completed',
        id: message.requestId,
        op: { request: item.op.request, response: message.operation.response }
      } as OperationQueueItem<SDKOperation>
    }
    return item
  })

  let completedCount = updatedItems.filter((item) => item.status === 'completed').length

  // 3) If >5 completed, remove the oldest ones first
  let toRemove = Math.max(0, completedCount - MAX_COMPLETED_QUEUE_ITEMS)
  const finalItems: OperationQueueItem<SDKOperation>[] = []

  // Prune completed items if there are too many
  for (const item of updatedItems) {
    if (item.status === 'completed' && toRemove > 0) {
      // This is an older completed item; discard it
      toRemove--
      continue
    }
    finalItems.push(item)
  }

  return { ...state, queue: { ...state.queue, items: finalItems } }
}

function mergeRequests(requests: SetFolderInputs[]) {
  const request: SetFolderInputs = {
    type: 'folders/setFolderInputs',
    folderId: requests[0].folderId,
    inputs: {}
  }
  for (const r of requests) {
    Object.assign(request.inputs, r.inputs)
  }
  return request
}

export function selFolderRole(state: STFolderModuleState): FolderUIRole {
  const membership = state.folderState!.memberships.find((m) => {
    return m.user.id == state.currentUserId
  })
  return membership?.scope == 'organization' ? 'organization_member' : 'client'
}

export function selQuestionnaireDocumentsOfType(
  state: STFolderModuleState,
  documentTypeId: string
) {
  const documentType = state.folderState!.documentTypes.find((dt) => dt.id == documentTypeId)

  if (!documentType) return []

  return state.folderState!.documents.filter((doc) => {
    const filterDocumentTypeId = doc.questionnaireDocumentTypeId
    // no document type so it is not included
    if (filterDocumentTypeId == undefined) {
      return false
    }

    // exact match of the document type
    else if (documentType.id == filterDocumentTypeId) {
      return true
    }

    // embedded document type (for example k_1_partnership would match k_1 because k_1_partnership is a "subtype" of k_1)
    else if (
      documentType.possibleEmbeddedDocumentTypeIds &&
      documentType.possibleEmbeddedDocumentTypeIds.includes(filterDocumentTypeId)
    ) {
      return true
    } else {
      return false
    }
  })
}

export function selDocumentTypeOptions(state: STFolderModuleState) {
  if (!state.folderState) return []
  return sort(
    state.folderState.documentTypes.filter((dt) => dt.id != 'stanfordtax'),
    asc('name')
  )
}

export function selResolvedInputs(state: STFolderModuleState) {
  if (!state.folderState) return {}

  const items = state.queue.items
  const inputs: JsonMap = {}

  Object.assign(inputs, state.folderState.inputs)

  for (var i = 0; i < items.length; i++) {
    const item = items[i]
    if (item.op.request.type == 'folders/setFolderInputs') {
      Object.assign(inputs, item.op.request.inputs)
    }
  }
  return inputs
}

/**
 * Get the client comment for a given key.
 *
 * @param state
 * @param clientCommentKey
 * @returns
 */
export function selClientComment(state: STFolderModuleState, clientCommentKey: string) {
  if (!state.folderState) return undefined

  const commands = state.queue.items
    .map((operation) => operation.op.request)
    .filter(
      (req): req is SetClientComment =>
        req.type == 'folders/setClientComment' && req.commentKey == clientCommentKey
    )

  const latestCommand = commands[commands.length - 1]

  if (latestCommand) {
    return latestCommand.commentBody
  } else {
    return state.folderState.clientComments.find((c) => c.key == clientCommentKey)?.body
  }
}

export function selReadInput(state: STFolderModuleState, key: string) {
  if (!state.folderState) return undefined

  const commands = state.queue.items
    .map((operation) => operation.op.request)
    .filter((req): req is SetFolderInputs => req.type == 'folders/setFolderInputs')

  const cmd = findLast(commands, (cmd) => key in cmd.inputs)

  return cmd ? cmd.inputs[key] : state.folderState.inputs[key]
}

export function selFieldDecoration(
  data: { inputs: JsonMap; priorInputs: JsonMap; fieldHints: FieldHint[] },
  key: string
): FieldDecoration | undefined {
  const fieldHint = data.fieldHints.find((h) => h.key == key)
  const hint = fieldHint?.text
  const curValue = data.inputs[key]

  return formationFieldDecoration({ hint, curValue, priorValue: data.priorInputs[key] })
}

export function selFieldComment(s: STOpenFolderState, key: string): FieldComment | undefined {
  const fieldComment = s.clientComments.find((c) => c.key == key)
  if (!fieldComment) return undefined

  const author = s.users.find((u) => {
    return u.id == fieldComment.authorId
  })

  return {
    key: fieldComment.key,
    body: fieldComment.body,
    author: author?.name ?? author?.email,
    time: fieldComment.time
  }
}

export function selFolderOperationInProgress(
  state: STFolderModuleState,
  type: FolderRequest['type']
) {
  return state.queue.items.some(
    (item) => item.status != 'completed' && item.op.request.type == type
  )
}

export function selChecklistItems(state: STFolderModuleState) {
  const commands = state.queue.items
    .map((operation) => operation.op.request)
    .filter((req): req is MoveChecklistItem => req.type == 'folders/moveChecklistItem')

  if (commands.length == 0) {
    return state.folderState!.checklistItems
  } else {
    // Make a shallow copy so we can apply moves optimistically.
    const optimisticChecklistItems = [...state.folderState!.checklistItems]

    // Apply each move command in order
    for (const cmd of commands) {
      const { checklistItemId, relation, referenceChecklistItemId } = cmd

      // 1) Find the index of the item we want to move.
      const itemIndex = optimisticChecklistItems.findIndex((item) => item.id === checklistItemId)
      if (itemIndex === -1) {
        // Item not found, skip.
        continue
      }

      // 2) Remove the item from the current list.
      const [itemToMove] = optimisticChecklistItems.splice(itemIndex, 1)

      // 3) Find the reference index (if any).
      let referenceIndex = -1
      if (referenceChecklistItemId) {
        referenceIndex = optimisticChecklistItems.findIndex(
          (item) => item.id === referenceChecklistItemId
        )
      }

      // 4) Calculate the target index based on the relation.
      let targetIndex: number
      switch (relation) {
        case 'before':
          // place it at the reference item’s position if found,
          // otherwise we could default to the end of the list or 0
          targetIndex = referenceIndex >= 0 ? referenceIndex : optimisticChecklistItems.length
          break
        case 'after':
          // place the item one slot after the reference if found
          targetIndex = referenceIndex >= 0 ? referenceIndex + 1 : optimisticChecklistItems.length
          break
        case 'start_of_list':
          targetIndex = 0
          break
        case 'end_of_list':
          targetIndex = optimisticChecklistItems.length
          break
        default:
          // Just in case the relation is unrecognized
          targetIndex = optimisticChecklistItems.length
          break
      }

      // 5) Insert the item into the new position.
      optimisticChecklistItems.splice(targetIndex, 0, itemToMove)
    }

    return optimisticChecklistItems
  }
}

export function selIncompleteChecklistItems(state: STFolderModuleState) {
  return state.folderState!.checklistItems.filter((item) => item.status == 'incomplete')
}

export function selFolderSaveStatus(state: STFolderModuleState): 'saving' | 'saved' {
  const runningOps = state.queue.items.filter((s) => s.status == 'running')
  return runningOps.length > 0 ? 'saving' : 'saved'
}

export function formatChecklistItem(item: STChecklistItem) {
  return item.name + (item.note ? ` ${item.note}` : '')
}

export function hasUploadedDocuments(documents: STDocument[]) {
  return documents.some((d) => d.documentTypeId != 'stanfordtax')
}

export function getBookmarkSections(state: {
  documentTypes: STDocumentType[]
  documents: STDocument[]
}): Section<STDocumentType, STDocument>[] {
  const sections: Section<STDocumentType, STDocument>[] = []
  for (const dt of state.documentTypes) {
    const section = {
      heading: dt,
      items: state.documents.filter(
        (d) =>
          (d.documentTypeId ?? UNCATEGORIZED_DOCUMENT_TYPE_ID) == dt.id &&
          d.processingStatus != 'processing'
      )
    }
    if (section.items.length > 0) {
      sections.push(section)
    }
  }

  const processingDocumentsSection: Section<STDocumentType, STDocument> = {
    heading: {
      id: 'processing',
      name: 'Processing...',
      expectedLate: false,
      missingReasons: [],
      possibleEmbeddedDocumentTypeIds: []
    },
    items: state.documents.filter((d) => d.processingStatus == 'processing')
  }
  if (processingDocumentsSection.items.length > 0) {
    sections.push(processingDocumentsSection)
  }

  const invalidCategoriesSection: Section<STDocumentType, STDocument> = {
    heading: {
      id: 'invalid_category',
      name: 'Invalid Category',
      expectedLate: false,
      missingReasons: [],
      possibleEmbeddedDocumentTypeIds: []
    },
    items: state.documents.filter((doc) => {
      // we find documents that are not in any of the other sections
      // they are considered uncategorized to prevent hidden documents in the UI
      for (const section of sections) {
        if (section.items.find((el) => el.id == doc.id)) {
          return false
        }
      }
      return true
    })
  }
  if (invalidCategoriesSection.items.length > 0) {
    sections.push(invalidCategoriesSection)
  }

  return sections
}

export function selSelectedDoc(state: STFolderModuleState) {
  if (!state.folderState) return undefined
  if (!state.selectedDocId) return undefined
  return state.folderState.documents.find((d) => d.id == state.selectedDocId)
}

export function selDoc(state: STFolderModuleState, docId: string | undefined) {
  if (!docId) return undefined
  if (!state.folderState) return undefined
  return state.folderState.documents.find((d) => d.id == docId)
}

export function selSuggestedCategoryIds(state: STFolderModuleState) {
  if (!state.folderState) return []
  return state.folderState.suggestedCategoryIds
}

export function formatFolderEntityName(
  entities: Entities | undefined,
  audience: 'client' | 'internal' = 'internal'
) {
  return formatEntities(entities ?? {}, audience)
}

export function selMissingReasonOptionsMap(state: {
  documentTypes: STDocumentType[]
  categories: STCategory[]
}): Record<string, STMissingReason[]> {
  const reasonsById: Record<string, STMissingReason[]> = {}

  for (const cat of state.categories) {
    reasonsById[cat.id] = cat.missingReasons
  }
  for (const documentType of state.documentTypes) {
    reasonsById[documentType.id] = documentType.missingReasons
  }

  return reasonsById
}

export function selMissingReasonsById(state: {
  documentTypes: STDocumentType[]
  categories: STCategory[]
}): Record<string, STMissingReason> {
  const map: Record<string, STMissingReason> = {}

  for (const reasons of Object.values(selMissingReasonOptionsMap(state))) {
    for (const r of reasons) {
      map[r.key] = r
    }
  }
  return map
}

export function selUploadedDocuments(state: STFolderModuleState): STDocument[] {
  if (!state.folderState) return []
  return state.folderState!.documents.filter((doc) => doc.documentTypeId != 'stanfordtax')
}

export function selDocumentTooltip(
  doc: STDocument,
  context: { memberships: ScopedFolderMembership[] }
) {
  if (!doc.uploadedAt) return undefined

  const uploaderMembership = context.memberships.find((m) => m.user.id == doc.uploadedBy)

  const byExtra = (() => {
    if (uploaderMembership?.scope == 'organization') return ' by firm '
    else if (uploaderMembership?.scope == 'folder') return ' by client '
    else return ''
  })()

  return ['Uploaded', byExtra, 'at ' + formatISO8601DateTime(doc.uploadedAt)]
    .filter(isNotEmpty)
    .join(' ')
}

export const deleteAllDocuments = defineTask(stFolderModule, async ({ getState, send }) => {
  const state = getState()

  const documents = selUploadedDocuments(state)
  const folderId = state.folderState!.folderId
  const requests: STFolderMessage[] = documents.map((doc) => {
    return {
      type: 'request',
      request: { type: 'folders/deleteDocument', folderId: folderId, documentId: doc.id }
    }
  })

  send(requests)
})

// op queue
type OperationQueue<Op extends Operation> = {
  lastId: string
  items: Array<OperationQueueItem<Op>>
}

type QueuedOperationQueueItem<Op extends Operation> = {
  id: string
  status: 'queued'
  op: UnresolvedOp<Op> // queued does not yet have a response
}

type UnresolvedOp<Op extends Operation> = { request: Op['request'] }

type RunningOperationQueueItem<Op extends Operation> = {
  id: string
  status: 'running'
  op: UnresolvedOp<Op> // queued does not yet have a response
}

type CompletedOperationItem<Op extends Operation> = {
  id: string
  status: 'completed'
  op: Op
}

type OperationQueueItem<Op extends Operation> =
  | QueuedOperationQueueItem<Op>
  | RunningOperationQueueItem<Op>
  | CompletedOperationItem<Op>

function addToQueue<Op extends Operation>(
  queue: OperationQueue<Op>,
  item: OperationQueueItem<Op>
): OperationQueue<Op> {
  return { ...queue, lastId: item.id, items: [...queue.items, item] }
}

/**
 * Merge queued operations (ones that have not yet started) into a single operation.
 * For example, if you are trying to set a bunch of key-value pairs, you don't want to send a request for each
 * item that is being typed up.
 *
 * @param queue
 * @param cond The condition for items in the queue to merge
 * @param merge Returns a new merged list
 *
 * @returns
 *  A new queue with the merged items
 */
function mergeQueuedOperations<Op extends Operation, SpecificOp extends Op>(
  queue: OperationQueue<Op>,
  cond: (item: QueuedOperationQueueItem<Op>) => item is QueuedOperationQueueItem<SpecificOp>,
  merge: (items: QueuedOperationQueueItem<SpecificOp>[]) => QueuedOperationQueueItem<SpecificOp>
): OperationQueue<Op> {
  function overallCond(item: OperationQueueItem<Op>): item is QueuedOperationQueueItem<SpecificOp> {
    return item.status == 'queued' && cond(item)
  }

  const itemsToMerge = queue.items.filter(overallCond)
  const mergedItem = merge(itemsToMerge)

  const finalItems: OperationQueueItem<Op>[] = []
  let replacedItem = false
  for (const item of queue.items) {
    if (!overallCond(item)) {
      // keep item not relva
      finalItems.push(item)
    } else if (item.id == mergedItem.id) {
      // replace the existing item
      finalItems.push(mergedItem)
      replacedItem = true
    }
  }
  if (!replacedItem) {
    finalItems.push(mergedItem)
  }

  return { ...queue, items: finalItems }
}

function nextQueueItemId(queue: OperationQueue<any>): string {
  return (Number(queue.lastId) + 1).toString()
}
