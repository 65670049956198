import { useAuthClient } from '@features/app-hooks'
import {
  documentUploadsModule,
  selUploads,
  Upload,
  uploadDocument
} from '@features/document-uploads'
import { ConfirmDeleteDocumentDialog } from '@features/formation-viewer/formation-wizard/confirm-delete-document-dialog'
import {
  formatFolderEntityName,
  selChecklistItems,
  selUploadedDocuments,
  stFolderModule
} from '@features/st-folder-viewer/st-folder-module'
import { useProcess, useProcessState } from '@st/redux'
import { STDocument } from '@st/sdk/src/sdk.types'
import {
  Button,
  Dialog,
  DialogButtons,
  FileUploadItem,
  MiniDocumentDropZone,
  Modal,
  PostItNote,
  QuestionnaireCard,
  QuestionnaireCardHeader,
  QuestionnaireChecklist,
  QuestionnaireChecklistRow,
  QuestionnaireDashboardContainer,
  QuestionnaireFile,
  QuestionnaireFilesList,
  RadialProgressBar
} from '@st/theme'
import { cuid } from '@st/util/cuid'
import { Progress } from '@st/util/progress'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { selQuestionnaireSavedProgress, stQuestionnaireModule } from './st-questionnaire-module'

type WizardDocumentAction = { type: 'confirmDelete'; doc: STDocument }

export function STQuestionnaireDashboard() {
  const authClient = useAuthClient()

  const stQuestionnaire = useProcess(stQuestionnaireModule)

  const documentUploads = useProcess(documentUploadsModule)

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const checklistItems = useProcessState(stFolderModule, selChecklistItems)

  const documents = useProcessState(stFolderModule, selUploadedDocuments)

  const uploads = useProcessState(documentUploadsModule, (s) => selUploads(s))
  const folder = useProcessState(stFolderModule, (s) => s.folderState!.folder)

  const savedProgress = useProcessState(stQuestionnaireModule, selQuestionnaireSavedProgress)
  const organization = useProcessState(stQuestionnaireModule, (s) => s.organization)
  const preview = useProcessState(stQuestionnaireModule, (s) => s.preview)

  const [alert, setAlert] = useState<'locked' | undefined>()

  const [action, setAction] = useState<WizardDocumentAction | undefined>()

  function onDropFiles(dt: DataTransfer) {
    for (const file of dt.files) {
      documentUploads.send(uploadDocument({ uploadId: cuid(), file, folderId: folderId }))
    }
  }

  return (
    <QuestionnaireDashboardContainer
      branding={<h1>{organization.name}</h1>}
      menu={
        !preview && (
          <Button variant="link" onClick={() => authClient.logout()}>
            Logout
          </Button>
        )
      }
    >
      <h1 className="text-3xl">
        {formatFolderEntityName(folder.entities, 'client')}, {folder.type} - Tax Year {folder.year}
      </h1>

      {match(folder.questionnaireStatus)
        .with('submitted', () => (
          <PostItNote signature={organization.name} variant="success" className="w-full md:w-2/3">
            {`Thank you for submitting your questionnaire. You can come back at any time to upload additional documents. We'll be in touch soon.`}
          </PostItNote>
        ))
        .otherwise(() => null)}

      <QuestionnaireCard>
        <QuestionnaireCardHeader
          step={1}
          title="Complete questionnaire"
          subtitle={
            checklistItems.length > 0
              ? `The questionnaire will go over the items below, and anything new which came up in ${folder.year}. Please gather your documents before you begin the questionnaire.`
              : `The questionnaire will go over the information we need in order to prepare your ${folder.year} return. Please gather your documents before you begin the questionnaire.`
          }
          right={<RadialProgressBar percentage={savedProgress.progress} size={60} />}
        />

        <QuestionnaireChecklist>
          {checklistItems.map((item) => {
            return (
              <QuestionnaireChecklistRow
                key={item.id}
                title={item.name}
                note={item.note}
                completed={item.status == 'complete'}
              />
            )
          })}
        </QuestionnaireChecklist>

        <div className="mt-4 flex flex-row justify-end">
          <Button
            variant="primary"
            className={folder.questionnaireStatus == 'submitted' ? 'opacity-80' : ''}
            onClick={() => {
              if (folder.questionnaireStatus == 'submitted') {
                setAlert('locked')
              } else {
                stQuestionnaire.send({ type: 'enterQuestionnaire' })
              }
            }}
          >
            Enter Questionnaire
          </Button>
        </div>
      </QuestionnaireCard>

      <QuestionnaireCard>
        <QuestionnaireCardHeader
          step={2}
          title="Upload additional documents"
          subtitle="Upload documents below if you weren't able to provide them in the questionnaire. "
        />

        <QuestionnaireDocumentUploader
          uploads={uploads}
          onDrop={onDropFiles}
          onClearUpload={(uploadId) => documentUploads.send({ type: 'clearUpload', uploadId })}
        />

        <QuestionnaireFilesList>
          {documents.map((doc) => {
            return (
              <QuestionnaireFile
                key={doc.id}
                onClickPreview={() => {
                  window.open(doc.urls.sourceInline, '_blank')
                }}
                onClickDelete={
                  folder.questionnaireStatus == 'submitted'
                    ? undefined
                    : () => {
                        setAction({ type: 'confirmDelete', doc })
                      }
                }
              >
                {doc.originalName}
              </QuestionnaireFile>
            )
          })}
        </QuestionnaireFilesList>
      </QuestionnaireCard>

      {match(alert)
        .with('locked', () => <QuestionnaireLockedAlert onClose={() => setAlert(undefined)} />)
        .otherwise(() => null)}

      {match(action)
        .with({ type: 'confirmDelete' }, ({ doc }) => (
          <Modal isOpen={true}>
            <ConfirmDeleteDocumentDialog
              doc={doc}
              onConfirm={() => {
                stQuestionnaire.send({ type: 'deleteDocument', docId: doc.id })
                setAction(undefined)
              }}
              onCancel={() => setAction(undefined)}
            />
          </Modal>
        ))
        .otherwise(() => null)}
    </QuestionnaireDashboardContainer>
  )
}

function QuestionnaireLockedAlert({ onClose }: { onClose: () => void }) {
  return (
    <Modal isOpen={true}>
      <Dialog
        title="Questionnaire locked"
        buttons={
          <DialogButtons>
            <Button onClick={onClose}>OK</Button>
          </DialogButtons>
        }
      >
        <p>The questionnaire is locked. Please contact us to unlock the questionnaire.</p>
        <p>If you would like to upload documents, please do so at at the bottom.</p>
      </Dialog>
    </Modal>
  )
}

function QuestionnaireDocumentUploader(props: {
  uploads: Upload[]
  onDrop: (dt: DataTransfer) => void
  onClearUpload: (uploadId: string) => void
}) {
  return (
    <div className="flex flex-col gap-2">
      <MiniDocumentDropZone onDrop={props.onDrop} />

      {props.uploads.map((upload) => {
        return (
          <FileUploadItem
            key={upload.id}
            status={upload.status}
            value={Progress.toNumber(upload.progress)}
            onClose={() => props.onClearUpload?.(upload.id)}
          >
            {upload.filename}
          </FileUploadItem>
        )
      })}
    </div>
  )
}
