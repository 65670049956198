import type { Core } from '@pdftron/webviewer'
import { ProcessSend } from '@st/redux'
import { range } from '@st/util/array'
import { hexToRgb } from '@st/util/color'
import { Anchor, LinearTransform, NormalizedRect, Point, Rect, Size } from '@st/util/geom'
import { ViewportGeometry, getTargetScrollPosition } from '@st/util/geom/viewport'
import { maxBy, minBy } from '@st/util/sort'
import { PDFViewerMessage, Page, TextSelection, PDFViewerError } from './module'
import {
  DocumentViewer,
  DocumentViewerEvent,
  loadPDFTronCore,
  PDFTronCore
} from '~/pdf-util/pdftron'

type PDFTronControllerOpts = {
  send: ProcessSend<PDFViewerMessage>
  src: string
  selector: string
  licenseKey: string
  onError?: (error: PDFViewerError) => void
}
export class PDFTronController implements Disposable {
  private static idCounter = 0

  /**
   * Handles to things
   */
  private disposeHandlers: Array<Function> = []

  private _overlayElements: HTMLDivElement[] = []

  private dispatch: ProcessSend<PDFViewerMessage>
  private documentViewer: DocumentViewer | undefined
  private core: PDFTronCore | undefined
  private id: number
  private disposed = false
  private onError: (error: PDFViewerError) => void

  /**
   * Simple state
   */
  constructor(opts: PDFTronControllerOpts) {
    this.dispatch = opts.send
    this.onError = opts.onError ?? (() => {})
    this.id = PDFTronController.idCounter++

    loadPDFTronCore().then((core: PDFTronCore) => {
      if (this.disposed) {
        console.warn('controller disposed before loading')
        return
      }

      this.core = core

      this.documentViewer = new core.DocumentViewer()
      this.documentViewer.enableAnnotations()

      const scrollViewElement = document!.querySelector(opts.selector)!
      const viewerElement = scrollViewElement.querySelector('.pdf-tron-viewer')!

      core.Tools.PanTool.disableDragScroll()

      this.documentViewer.setScrollViewElement(scrollViewElement)
      this.documentViewer.setViewerElement(viewerElement)
      this.documentViewer.setFitMode(this.documentViewer.FitMode.FitWidth)

      this.setTool('none')

      this.documentViewer.loadDocument(opts.src, {
        licenseKey: opts.licenseKey,
        loadAnnotations: true,
        isRelativePath: false,
        useDownloader: true,
        loadAsPDF: true,
        forceClientSideInit: false,
        fallbackToClientSide: false,
        onError: (error) => {
          const viewerError = normalizeApryseLoadError(error, opts.src)
          this.dispatch({ type: 'loadFailed', error: viewerError })
          this.onError(viewerError)
        }
      })

      this.documentViewer.getAnnotationManager().setRotationOptions({ isEnabled: false })

      this.disposeHandlers.push(() => {
        this.documentViewer!.dispose()
      })

      this.registerEventHandlers()
    })
  }

  toPDFColor = (color: string) => {
    const { r, g, b } = hexToRgb(color)!
    return new this.core!.Annotations.Color(r, g, b, 1)
  }

  getOrCreateOverlayElement = (pageIndex: number) => {
    const el = this._overlayElements[pageIndex]
    if (el) {
      return el
    }
    this._overlayElements[pageIndex] = createOverlayElement()
    return this._overlayElements[pageIndex]!
  }

  loadPageThumbnail = (pageNumber: number, onLoad: (canvas: HTMLCanvasElement) => void) => {
    const docViewer = this.documentViewer!
    const doc = docViewer.getDocument()
    const id = doc.loadCanvas({
      allowUseOfOptimizedThumbnail: true,
      width: 150,
      height: 150,
      pageNumber: pageNumber,
      drawComplete: onLoad
    })
    return () => doc.cancelLoadCanvas(Number(id))
  }

  setZoomLevel = (zoomLevel: number) => {
    this.documentViewer!.zoomTo(zoomLevel)
  }

  scrollTo = (pageIndex: number, rect: Rect) => {
    const geom = this.getViewportGeometry(pageIndex, rect)

    const scrollPosition = getTargetScrollPosition({
      geom: geom,
      viewportAnchor: Anchor.center,
      childAnchor: Anchor.center
    })

    this.documentViewer!.getScrollViewElement().scrollTo({
      left: scrollPosition.x,
      top: scrollPosition.y,
      behavior: 'smooth'
    })
  }

  private getViewportGeometry = (pageIndex: number, rectInPage: Rect): ViewportGeometry => {
    const scrollView = this.documentViewer!.getScrollViewElement() as HTMLElement
    const pageContainer = this.getPageContainer(pageIndex)!
    const zoom = this.documentViewer!.getZoomLevel()
    const pageGeom = getViewportGeometry(scrollView, pageContainer)

    const transformedRect = LinearTransform.apply(rectInPage, LinearTransform.scale(zoom))
    return {
      ...pageGeom,
      childRect: {
        x: pageGeom.childRect.x + transformedRect.x,
        y: pageGeom.childRect.y + transformedRect.y,
        width: transformedRect.width,
        height: transformedRect.height
      }
    }
  }

  selectAnnotation = (annotationId: string) => {
    const annotationManager = this.documentViewer!.getAnnotationManager()
    const annotation = annotationManager.getAnnotationById(annotationId)

    annotationManager.deselectAllAnnotations()
    annotationManager.selectAnnotation(annotation)
    setTimeout(
      () =>
        annotationManager.jumpToAnnotation(annotation, {
          isSmoothScroll: true
        }),
      0
    )
  }

  setTool = async (toolName: 'select' | 'none') => {
    const ToolNames = window.Core.Tools.ToolNames
    const documentViewer = this.documentViewer!

    switch (toolName) {
      case 'select':
        documentViewer.setToolMode(documentViewer.getTool(ToolNames.EDIT))
        break
      case 'none':
        documentViewer.setToolMode(documentViewer.getTool(ToolNames.PAN))
        break
    }
  }

  private getPageSize = (pageIndex: number): Size => {
    return {
      width: this.documentViewer!.getPageWidth(pageIndex + 1)!,
      height: this.documentViewer!.getPageHeight(pageIndex + 1)!
    }
  }

  private getSelectedText = (pageIndex: number): string => {
    return this.documentViewer!.getSelectedText(pageIndex + 1)
  }

  private registerEventHandlers = () => {
    // document viewer events
    const documentViewer = this.documentViewer!
    this.onDocumentViewer('DOCUMENT_LOADED', () => {
      const pageCount = documentViewer.getPageCount()

      const documentPages = range(0, pageCount - 1).map((pageIndex) => {
        return {
          index: pageIndex,
          size: this.getPageSize(pageIndex)
        } satisfies Page
      })

      this.dispatch({
        type: 'loaded',
        pages: documentPages,
        zoomLevel: documentViewer.getZoomLevel()
      })
    })

    this.onDocumentViewer('PAGE_NUMBER_UPDATED', () => {
      this.dispatch({
        type: 'pageSelected',
        page: documentViewer.getCurrentPage()
      })
    })

    this.onDocumentViewer('ZOOM_UPDATED', () => {
      const zoomLevel = documentViewer.getZoomLevel()
      console.log('zoomLevel', this.id, zoomLevel)

      this.dispatch({
        type: 'zoomUpdated',
        zoomLevel: documentViewer.getZoomLevel()
      })
    })

    this.onDocumentViewer('ANNOTATIONS_LOADED', () => {})

    this.onDocumentViewer('FINISHED_RENDERING', () => {
      this.maybeAppendPageContainers()
    })

    this.onDocumentViewer(
      'TEXT_SELECTED',
      (quads: Core.Math.Quad[], selectedText: string, pageNumber: number) => {
        // use clicked away to deselecta all of the text
        if (quads === null) {
          this.dispatch({ type: 'textDeselected' })
        }
      }
    )

    const ToolNames = window.Core.Tools.ToolNames
    const tool = documentViewer.getTool(ToolNames.TEXT_SELECT)
    tool.addEventListener(
      'selectionComplete',
      (
        startQuad: { quad: Core.Math.Quad; pageNumber: number },
        // page number to array of quads
        allQuads: Record<string, Core.Math.Quad[]>
      ) => {
        const textSelections: TextSelection[] = Object.entries(allQuads).map(
          ([pageNumber, selectedQuadsOnPage]) => {
            const pageIndex = Number(pageNumber) - 1
            const pageSize = this.getPageSize(pageIndex)

            return {
              pageIndex,
              text: this.getSelectedText(pageIndex),
              rect: normalizedRectForQuads(selectedQuadsOnPage, pageSize)
            }
          }
        )

        this.dispatch({ type: 'textSelected', textSelections })
      }
    )
  }

  private maybeAppendPageContainers() {
    const pageCount = this.documentViewer!.getPageCount()
    for (var pageIndex = 0; pageIndex < pageCount; pageIndex++) {
      const pageContainer = this.getPageContainer(pageIndex)

      if (!pageContainer) {
        continue
      }

      // overlay has already been inserted so we can skip creating a new one
      if (pageContainer.getElementsByClassName('pdf-page-overlay').length > 0) {
        continue
      }

      pageContainer.appendChild(this.getOrCreateOverlayElement(pageIndex))
    }
  }

  private getPageContainer = (pageIndex: number) => {
    return this.documentViewer!.getScrollViewElement().querySelector(
      `#pageContainer${pageIndex + 1}`
    ) as HTMLElement | undefined
  }

  private onDocumentViewer = <T extends any[] = []>(
    event: DocumentViewerEvent,
    eventHandler: (...args: T) => void
  ) => {
    const eventName = this.core!.DocumentViewer.Events[event]
    this.documentViewer!.addEventListener(eventName, eventHandler)
  };

  [Symbol.dispose] = () => {
    this.disposed = true

    for (const handler of this.disposeHandlers) {
      handler()
    }
  }
}

function createOverlayElement() {
  const overlayElement = document.createElement('div')
  overlayElement.className = `pdf-page-overlay`
  overlayElement.style.position = 'absolute'
  overlayElement.style.left = '0px'
  overlayElement.style.top = '0px'
  overlayElement.style.right = '0px'
  overlayElement.style.bottom = '0px'
  // overlayElement.style.backgroundColor = 'rgba(255, 0, 0, 0.1)'
  // overlayElement.style.opacity = '0.2'
  overlayElement.style.zIndex = '10000'
  return overlayElement
}

function normalizedRectForQuads(quads: Core.Math.Quad[], pageSize: Size): NormalizedRect {
  const xCoords = quads
    .flatMap((quad) => [quad.x1, quad.x2, quad.x3, quad.x4])
    .map((x) => x / pageSize.width)

  const yCoords = quads
    .flatMap((quad) => [quad.y1, quad.y2, quad.y3, quad.y4])
    .map((y) => y / pageSize.height)

  const topLeft: Point = {
    x: minBy(xCoords, (x) => x)!,
    y: minBy(yCoords, (y) => y)!
  }

  const bottomRight: Point = {
    x: maxBy(xCoords, (x) => x)!,
    y: maxBy(yCoords, (y) => y)!
  }

  return {
    x: topLeft.x,
    y: topLeft.y,
    width: bottomRight.x - topLeft.x,
    height: bottomRight.y - topLeft.y
  }
}

function getViewportGeometry(
  scrollElement: HTMLElement,
  childElement: HTMLElement
): ViewportGeometry {
  const viewportRect = domRectToRect(scrollElement.getBoundingClientRect())

  return {
    viewportRect: viewportRect,
    childRect: domRectToRect(childElement.getBoundingClientRect()),
    scrollAreaRect: {
      x: viewportRect.x - scrollElement.scrollLeft,
      y: viewportRect.y - scrollElement.scrollTop,
      width: scrollElement.scrollWidth,
      height: scrollElement.scrollHeight
    }
  }

  function domRectToRect(rect: DOMRect): Rect {
    return { x: rect.x, y: rect.y, width: rect.width, height: rect.height }
  }
}

function normalizeApryseLoadError(
  error: { type: string; message: string } | string,
  src: string
): PDFViewerError {
  if (typeof error == 'string') {
    if (error.toLowerCase().includes('requires a password')) {
      return { type: 'passwordProtected', message: 'PDF is password protected', src }
    } else {
      return { type: 'unknown', message: error, src }
    }
  } else if (error.type == 'InvalidPDF') {
    return { type: 'invalid', message: 'Invalid PDF, unable to load file.', src }
  } else {
    return { ...error, src }
  }
}
