import { CollectionApp } from '@features/collection'
import { getAppType } from '@features/get-app-type'
import { OrganizationApp } from '@features/organizations'

export function App() {
  switch (getAppType()) {
    case 'organization':
      return <OrganizationApp />
    case 'client':
      return <CollectionApp />
  }
}
