import { useAppDeps } from '@features/app-deps-provider'
import { useSetRoute } from '@features/app-hooks'
import { useOrganization } from '@features/organizations'
import { clientFolderShareableLink, routes } from '@features/routing'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { STSendQuestionnaireDialog } from '@features/st-folder-viewer/st-send-questionnaire-dialog'
import { STAppHeaderNav } from '@features/st-organizations'
import { stOrganizationModule } from '@features/st-organizations/st-organization-module'
import { platformModule } from '@features/st-pdf-viewer/platform-module'
import { STQuestionnairesSendPage } from '@features/st-questionnaire-send'
import { STQuestionnaireDownloadCSVPage } from '@features/st-questionnaire-send/st-questionnaire-download-csv-page'
import { useProcess, useProcessState } from '@st/redux'
import {
  BatchActionToolbar,
  BellIcon,
  Button,
  Dialog,
  DialogButtons,
  DownloadIcon,
  GearIcon,
  Modal,
  PlusIcon,
  SelectableTagOption,
  SendIcon,
  TrashIcon
} from '@st/theme'
import { setDiff, setToggle } from '@st/util/array-set'
import { inflect } from '@st/util/inflect'
import { copyToClipboard } from '@util/clipboard'
import { ReactNode, useEffect, useState } from 'react'
import { match } from 'ts-pattern'
import { STEnrollInRemindersPage } from './st-enroll-in-reminders-page'
import { STFolderListFiltersBar } from './st-folder-list-filters-bar'
import { stFolderListFiltersModule } from './st-folder-list-filters-module'
import {
  BulkActionType,
  FolderRow,
  selAvailableBulkActions,
  selFinalFolderList,
  STFolderAction,
  stFolderListModule,
  unenrollFromReminders
} from './st-folder-list-module'
import { STFolderListTable } from './st-folder-list-table'
import { STCopyShareableLinkDialog } from '@features/st-folder-viewer/st-copy-shareable-link-dialog'

export function STFolderListPage() {
  const { socket } = useAppDeps()
  const organization = useOrganization()

  const setRoute = useSetRoute()
  const platform = useProcess(platformModule)

  const stFolderList = useProcess(stFolderListModule, { organizationId: organization.id })
  const stFolderListFilters = useProcess(stFolderListFiltersModule)

  const state = useProcessState(stFolderListModule, (s) => s)
  const availableBulkActions = useProcessState(stFolderListModule, selAvailableBulkActions)
  const filtersState = useProcessState(stFolderListFiltersModule, (s) => s)

  const [action, setAction] = useState<STFolderAction | undefined>()

  useEffect(() => {
    const topic = `folder_list:${organization.id}`
    const unsub = socket.subscribe(topic)

    socket.on(topic, 'state:load', (e: any) => {
      stFolderList.send({ type: 'folderListUpdated', folderId: e.folderId })
    })

    return () => unsub()
  }, [socket, organization.id, stFolderList])

  function onAction(action: STFolderAction) {
    switch (action.type) {
      case 'openFolder':
        setRoute({
          name: 'organization_folder',
          organizationSlug: organization.slug,
          folderId: action.folder.id
        })
        break
      case 'copyQuestionnaireLink':
        copyToClipboard(
          clientFolderShareableLink({
            organizationSlug: organization.slug,
            folderId: action.folderId
          })
        )
        platform.send({
          type: 'showSnackbar',
          message: 'Copied shareable link to clipboard'
        })
        break
      case 'previewQuestionnaire':
        window.open(
          routes.toPath({
            name: 'organization_preview_questionnaire',
            folderId: action.folderId,
            organizationSlug: organization.slug
          })
        )
        break
      default:
        setAction(action)
    }
  }

  if (action?.type == 'sendQuestionnaires') {
    return (
      <STQuestionnairesSendPage
        folderIds={action.folderIds}
        onClose={() => setAction(undefined)}
        onClickEnrollInReminders={(folderIds) =>
          setAction({ type: 'enrollInReminders', folderIds })
        }
      />
    )
  } else if (action?.type == 'enrollInReminders') {
    return (
      <STEnrollInRemindersPage folderIds={action.folderIds} onClose={() => setAction(undefined)} />
    )
  }

  if (action?.type == 'downloadQuestionnaireLinks') {
    return (
      <STQuestionnaireDownloadCSVPage
        folderIds={action.folderIds}
        onClose={() => setAction(undefined)}
        onClickBack={() => setAction(undefined)}
      />
    )
  }

  switch (state.status) {
    case 'loading':
      return (
        <FolderListScaffold
          title="Workpapers"
          header={<STAppHeaderNav />}
          filters={
            <STFolderListFiltersBar
              filters={filtersState.filters}
              send={stFolderListFilters.send}
            />
          }
        >
          <></>
        </FolderListScaffold>
      )
    case 'loaded':
      if (state.items.length == 0) {
        return (
          <FolderListScaffold title="Workpapers" header={<STAppHeaderNav />}>
            <FolderListEmptyState onAddClients={() => setRoute({ name: 'create_import' })} />
          </FolderListScaffold>
        )
      }

      const filteredFolers = selFinalFolderList(state, { stFolderListFilters: filtersState })

      return (
        <FolderListScaffold
          title="Workpapers"
          header={<STAppHeaderNav />}
          filters={
            <STFolderListFiltersBar
              filters={filtersState.filters}
              send={stFolderListFilters.send}
            />
          }
          buttons={
            <div className="ml-auto grid auto-cols-max grid-flow-col items-center gap-2">
              <Button
                leadingIcon={<DownloadIcon className="h-4 w-4" />}
                variant="default"
                onClick={() =>
                  onAction({
                    type: 'downloadQuestionnaireLinks',
                    folderIds: state.items.map((f) => f.id)
                  })
                }
              >
                Questionnaire Links
              </Button>
              <Button
                variant="default"
                leadingIcon={<PlusIcon className="h-4 w-4" />}
                onClick={() => setRoute({ name: 'create_import' })}
              >
                Clients
              </Button>
            </div>
          }
          batchActions={
            state.selectedFolderIds.length > 0 ? (
              <STFolderListBatchActions
                availableBulkActions={availableBulkActions}
                selectedFolderIds={state.selectedFolderIds}
                onAction={onAction}
                onClearSelection={() => stFolderList.send({ type: 'deselectAll' })}
              />
            ) : undefined
          }
        >
          <STFolderListTable
            items={filteredFolers}
            selectedFolderIds={state.selectedFolderIds}
            sortState={filtersState.sortState}
            send={stFolderList.send}
            onAction={onAction}
            onToggleSort={(column) => stFolderListFilters.send({ type: 'toggleSort', column })}
          />

          {match(action)
            .with({ type: 'openSendQuestionnaireDialog' }, ({ folder }) => (
              <Modal isOpen={true}>
                <STSendQuestionnaireDialog
                  folderId={folder.id}
                  onClose={() => setAction(undefined)}
                />
              </Modal>
            ))
            .with({ type: 'openCopyShareableLinkDialog' }, ({ folder }) => {
              return (
                <Modal isOpen={true}>
                  <STCopyShareableLinkDialog
                    folderId={folder.id}
                    onClose={() => setAction(undefined)}
                  />
                </Modal>
              )
            })
            .with({ type: 'deleteFolder' }, ({ folder }) => {
              return (
                <Modal isOpen={true}>
                  <STDeleteFolderDialog folder={folder} onClose={() => setAction(undefined)} />
                </Modal>
              )
            })
            .with({ type: 'editFolderTags' }, ({ folder }) => {
              return (
                <Modal isOpen={true}>
                  <STManageFolderTags folder={folder} onClose={() => setAction(undefined)} />
                </Modal>
              )
            })
            .with({ type: 'deleteFolders' }, ({ folderIds }) => {
              return (
                <Modal isOpen={true}>
                  <STConfirmDeleteFoldersDialog
                    folderIds={folderIds}
                    onClose={() => setAction(undefined)}
                  />
                </Modal>
              )
            })
            .with({ type: 'unenrollFromReminders' }, ({ folderIds }) => {
              return (
                <Modal isOpen={true}>
                  <STConfirmUnenrollFromRemindersDialog
                    onClose={() => setAction(undefined)}
                    organizationId={organization.id}
                    folderIds={folderIds}
                  />
                </Modal>
              )
            })
            .with({ type: 'viewRemindersSchedule' }, ({ folder }) => {
              return (
                <Modal isOpen={true}>
                  <Dialog
                    title="Automated Reminder"
                    subtitle={`Automated reminders begin on February 10th. A reminder will be sent to ${formatFolderEntityName(
                      folder.entities,
                      'client'
                    )} every Monday morning until they submit their questionnaire. You can view or modify the reminder email template in your Settings.`}
                    buttons={
                      <DialogButtons>
                        <Button
                          variant="default"
                          onClick={() => {
                            stFolderList.send(unenrollFromReminders(folder.id))
                            setAction(undefined)
                          }}
                        >
                          Unenroll from reminders
                        </Button>
                        <Button variant="primary" onClick={() => setAction(undefined)}>
                          OK
                        </Button>
                      </DialogButtons>
                    }
                  />
                </Modal>
              )
            })
            .otherwise(() => null)}
        </FolderListScaffold>
      )
  }
}

function FolderListScaffold({
  title,
  filters,
  header,
  children,
  buttons,
  batchActions
}: {
  title: string
  filters?: ReactNode
  header: ReactNode
  children: ReactNode
  buttons?: ReactNode
  batchActions?: ReactNode
}) {
  return (
    <div className={'grid h-dvh min-w-[1200px] grid-rows-[auto_auto_minmax(0,1fr)_auto]'}>
      {header}

      <div className="flex h-16 flex-row items-center px-20 py-12">
        <h1 className="mr-6 text-2xl">{title}</h1>
        {filters ?? <div />}
        {buttons ?? <div />}
      </div>

      {children}

      {batchActions}
    </div>
  )
}

function STFolderListBatchActions({
  availableBulkActions,
  selectedFolderIds,
  onClearSelection,
  onAction
}: {
  availableBulkActions: BulkActionType[]
  selectedFolderIds: string[]
  onClearSelection: () => void
  onAction: (action: STFolderAction) => void
}) {
  return (
    <BatchActionToolbar
      selectionMessage={`${selectedFolderIds.length} selected`}
      onClearSelection={onClearSelection}
    >
      {availableBulkActions.map((action) => {
        switch (action.type) {
          case 'sendQuestionnaires':
            return (
              <BatchActionToolbar.Button
                key={action.type}
                icon={SendIcon}
                onClick={() => onAction(action)}
              >
                Send questionnaires
              </BatchActionToolbar.Button>
            )
          case 'enrollInReminders':
            return (
              <BatchActionToolbar.Button
                key={action.type}
                icon={BellIcon}
                onClick={() => onAction(action)}
              >
                Enroll in reminders
              </BatchActionToolbar.Button>
            )
          case 'unenrollFromReminders':
            return (
              <BatchActionToolbar.Button
                key={action.type}
                icon={BellIcon}
                onClick={() => onAction(action)}
              >
                Unenroll from reminders
              </BatchActionToolbar.Button>
            )
          case 'deleteFolders':
            return (
              <BatchActionToolbar.Button
                key={action.type}
                icon={TrashIcon}
                onClick={() => onAction(action)}
              >
                Delete
              </BatchActionToolbar.Button>
            )
        }
      })}
    </BatchActionToolbar>
  )
}

function STConfirmDeleteFoldersDialog({
  onClose,
  folderIds
}: {
  onClose: () => void
  folderIds: string[]
}) {
  const { sdk } = useAppDeps()
  const platform = useProcess(platformModule)

  return (
    <Dialog
      title="Delete workpapers"
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              await Promise.all(
                folderIds.map((id) =>
                  sdk.send({
                    type: 'folders/deleteFolder',
                    folderId: id
                  })
                )
              )
              platform.send({
                type: 'showSnackbar',
                message: `Deleted ${folderIds.length} ${inflect(folderIds.length, 'workpaper')}`
              })
              onClose?.()
            }}
          >
            Confirm delete
          </Button>
        </DialogButtons>
      }
    >
      <p>
        Please confirm whether you would like to delete {folderIds.length}{' '}
        {inflect(folderIds.length, 'workpaper')}.
      </p>
    </Dialog>
  )
}
function STDeleteFolderDialog({ folder, onClose }: { folder: FolderRow; onClose: () => void }) {
  const { sdk } = useAppDeps()
  const platform = useProcess(platformModule)
  const [deleting, setDeleting] = useState(false)

  return (
    <Dialog
      title="Delete workpaper"
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={() => onClose()} disabled={deleting}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={deleting}
            onClick={async () => {
              setDeleting(true)
              try {
                await sdk.send({
                  type: 'folders/deleteFolder',
                  folderId: folder.id
                })
                platform.send({
                  type: 'showSnackbar',
                  message: `Deleted workpaper for ${formatFolderEntityName(folder.entities)}`
                })
                onClose?.()
              } finally {
                setDeleting(false)
              }
            }}
          >
            {deleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogButtons>
      }
    >
      <p>
        Please confirm whether you would like to delete the workpaper for{' '}
        {formatFolderEntityName(folder.entities)}.
      </p>
    </Dialog>
  )
}

function STManageFolderTags({ folder, onClose }: { folder: FolderRow; onClose: () => void }) {
  const { sdk } = useAppDeps()
  const platform = useProcess(platformModule)
  const setRoute = useSetRoute()

  const tags = useProcessState(stOrganizationModule, (s) =>
    (s.state?.folderTags ?? []).filter((t) => !t.deleted)
  )

  const [tagIds, setTagIds] = useState(folder.tagIds)

  const [saving, setSaving] = useState(false)

  if (tags.length == 0) {
    return (
      <Dialog
        title="Add tags"
        subtitle="You haven't created any tags yet. To manage tags go to Settings → Tags, or just click the “Manage tags” button."
        buttons={
          <DialogButtons>
            <Button variant="default" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              leadingIcon={<GearIcon className="h-4 w-4" />}
              onClick={() => setRoute({ name: 'manage_organization_tags' })}
            >
              Manage tags
            </Button>
          </DialogButtons>
        }
      />
    )
  }

  return (
    <Dialog
      title={`Add tags for ${formatFolderEntityName(folder.entities)}`}
      subtitle={`Add any of the following tags. You can manage your tags in Settings.`}
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={saving}
            onClick={async () => {
              const diff = setDiff({ old: folder.tagIds, new: tagIds })
              // nothing to change
              if (diff.add.length == 0 && diff.remove.length == 0) {
                onClose?.()
              }

              setSaving(true)
              await sdk.send({
                type: 'folders/updateFolderTags',
                folderId: folder.id,
                addTagIds: diff.add,
                removeTagIds: diff.remove
              })
              setSaving(false)

              platform.send({
                type: 'showSnackbar',
                message: `Updated tags for ${formatFolderEntityName(folder.entities)}`
              })
              onClose?.()
            }}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        {tags.map((t) => {
          return (
            <SelectableTagOption
              key={t.id}
              color={t.color}
              selected={tagIds.includes(t.id)}
              onClick={() => setTagIds(setToggle(tagIds, t.id))}
            >
              {t.label}
            </SelectableTagOption>
          )
        })}
      </div>
    </Dialog>
  )
}
function STConfirmUnenrollFromRemindersDialog({
  onClose,
  organizationId,
  folderIds
}: {
  onClose: () => void
  organizationId: string
  folderIds: string[]
}) {
  const { sdk } = useAppDeps()
  const platform = useProcess(platformModule)
  const [unenrolling, setUnenrolling] = useState(false)

  return (
    <Dialog
      title="Unenroll from reminders"
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={() => onClose()} disabled={unenrolling}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={unenrolling}
            onClick={async () => {
              setUnenrolling(true)
              try {
                await sdk.send({
                  type: 'folders/bulkUnenrollQuestionnairesFromReminders',
                  organizationId: organizationId,
                  folderIds: folderIds
                })
                platform.send({
                  type: 'showSnackbar',
                  message: `Unenrolled ${folderIds.length} ${inflect(
                    folderIds.length,
                    'client'
                  )} from automated reminders`
                })
                onClose?.()
              } finally {
                setUnenrolling(false)
              }
            }}
          >
            {unenrolling ? 'Unenrolling...' : 'Confirm unenroll'}
          </Button>
        </DialogButtons>
      }
    >
      <p>
        Please confirm whether you would like to unenroll {folderIds.length}{' '}
        {inflect(folderIds.length, 'client')} from automated reminders. You can re-enroll them at
        any time.
      </p>
    </Dialog>
  )
}

function FolderListEmptyState({ onAddClients }: { onAddClients: () => void }) {
  return (
    <div className="mx-auto mt-12 flex w-[650px] flex-col items-center gap-3">
      <div className="text-center text-xl text-gray-900">
        Preview a questionnaire by adding your first client!
      </div>
      <div className="text-center text-base text-gray-700">
        {`Add clients directly from your tax software and we'll generate a personalized checklist and
        questionnaire for each one. It's free to try, and takes less than 3 minutes.`}
      </div>
      <Button
        variant="primary"
        leadingIcon={<PlusIcon className="h-4 w-4" />}
        className="mt-4"
        onClick={onAddClients}
      >
        Add clients
      </Button>
    </div>
  )
}
