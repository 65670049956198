import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/globals.css'

import { App } from './app'
import { initSentry } from './init-sentry'

bootApp()

ReactDOM.createRoot(document.getElementById('root')!).render(
  // react strict mode is disabled because it is finnicky with the PDFTron/Apryse viewer
  // it makes debugging harder becdause of the create/dispose/create lifecycle of the controller
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

function bootApp() {
  console.log(`Starting app with build ${process.env.NEXT_PUBLIC_BUILD_COMMIT_SHA ?? 'dev'}`)
  initSentry()
}
