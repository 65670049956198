import { useAnalytics } from '@features/analytics'
import { useAppDeps } from '@features/app-deps-provider'
import { useAuthState } from '@features/app-hooks'
import { FormationViewerProvider } from '@features/formation-viewer'
import { useOrganization } from '@features/organizations'
import { useInputsWrite, useRead } from '@features/st-folder-viewer/st-folder-hooks'
import { FolderUIRole, stFolderModule } from '@features/st-folder-viewer/st-folder-module'
import { WizardConfig } from '@st/ui-config'
import { ReadWriteProvider } from '@st/pdf'
import { useProcess, useProcessState } from '@st/redux'
import { Organization } from '@st/sdk'
import { getFormConfig } from '@st/tax-folder'
import { StateDiff, StateLoad } from '@util/remote-socket'
import { useEffect, useState } from 'react'
import { match, P } from 'ts-pattern'
import { STQuestionnaireDashboard } from './st-questionnaire-dashboard'
import { stQuestionnaireModule } from './st-questionnaire-module'
import { STQuestionnairePage } from './st-questionnaire-page'
import { Title } from '@st/react-util/title'

type Props = { folderId: string; role: FolderUIRole; preview?: boolean }
export function STQuestionnaire({ folderId, role, preview = false }: Props) {
  const { user } = useAuthState()
  const analytics = useAnalytics()
  const organization = useOrganization()
  const { sdk, socket } = useAppDeps()

  const stFolder = useProcess(stFolderModule, { currentUserId: user!.id })

  const schemaId = useProcessState(stFolderModule, (s) => s.folderState?.schemaId)
  const [wizardConfig, setWizardConfig] = useState<WizardConfig | undefined>()

  useEffect(() => {
    const unsub = socket.subscribe(`folder:${folderId}`)
    socket.on(`folder:${folderId}`, 'state:load', (e: StateLoad) => {
      stFolder.send({ type: 'stateLoad', vsn: e.vsn, state: e.state })
    })
    socket.on(`folder:${folderId}`, 'state:diff', (e: StateDiff) => {
      stFolder.send({ type: 'stateDiff', fromVsn: e.fromVsn, toVsn: e.toVsn, diff: e.diff })
    })

    return () => unsub()
  }, [socket, folderId, stFolder])

  useEffect(() => {
    if (!schemaId) {
      return
    }

    getFormConfig(schemaId).then((formConfig) => {
      setWizardConfig(formConfig!.wizard!)
    })
  }, [schemaId])

  useEffect(() => {
    if (role == 'client') {
      sdk.send({ type: 'folders/markAsOpened', folderId: folderId })

      analytics.setGroup('organization', organization!.id)
      analytics.setGroup('folder', folderId)
      analytics.track('client_folder_opened')
    }
  }, [folderId, role])

  if (!wizardConfig) {
    return null
  }

  return (
    <FormationViewerProvider branding={{ companyName: organization.name }}>
      <Title>{organization.name}</Title>
      <QuestionnaireViewerInner
        organization={organization}
        wizardConfig={wizardConfig}
        preview={preview}
      />
    </FormationViewerProvider>
  )
}

function QuestionnaireViewerInner({
  organization,
  wizardConfig,
  preview
}: {
  organization: Organization
  wizardConfig: WizardConfig
  preview: boolean
}) {
  useProcess(stQuestionnaireModule, { organization, wizardConfig, preview })
  const route = useProcessState(stQuestionnaireModule, (s) => s.route)

  return (
    <ReadWriteProvider useRead={useRead} useWrite={useInputsWrite}>
      {match(route)
        .with(P.nullish, () => <STQuestionnaireDashboard />)
        .with(P.nonNullable, () => <STQuestionnairePage />)
        .run()}
    </ReadWriteProvider>
  )
}
