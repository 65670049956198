import { QuestionTip } from '@st/theme'
import { RadioSelectConfig } from '@st/ui-config'
import { ConfigInputProps } from '@util/input-component'
import { clsx } from 'clsx'
import { ChangeEvent, useId, useState } from 'react'

type Props = ConfigInputProps<RadioSelectConfig, string | undefined>
export function RadioSelect({ value, options, onChange }: Props) {
  const [selectedKey, setValue] = useState(value)

  function onSelectOption(key: string): void {
    setValue(key)
    onChange(key)
  }

  const optionsComponents = options.map((opt) => {
    return (
      <RadioSelectOption
        {...opt}
        key={opt.key}
        selected={opt.key == selectedKey}
        onSelect={() => onSelectOption(opt.key)}
      />
    )
  })

  return (
    <div className="flex w-full flex-col items-stretch gap-5 p-0.5" style={{ padding: 2 }}>
      {optionsComponents}
    </div>
  )
}

type RadioSelectOptionProps = {
  label?: string
  tip?: { title?: string; body?: string } | null
  onSelect: () => void
  selected?: boolean
}
export function RadioSelectOption({ label, tip, selected, onSelect }: RadioSelectOptionProps) {
  const id = useId()

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      onSelect()
    }
  }

  return (
    <div
      className={clsx(
        'h-16 cursor-pointer rounded-lg px-5 font-bold outline outline-1 outline-gray-200',
        'flex flex-row items-center',
        {
          'bg-gradient-to-tr from-[#526bf0] to-[#8572ff] text-white outline-none hover:outline-none':
            selected,
          'hover:outline-2 hover:outline-blue-500': !selected
        }
      )}
      onClick={() => onSelect()}
    >
      <input type="radio" checked={selected} id={id} onChange={onChange} />
      <label className="ml-2 cursor-pointer text-lg font-bold" htmlFor={id}>
        <span>{label}</span>
        {tip ? <span>&nbsp;</span> : null}
        {tip && (
          <QuestionTip title={tip.title} body={tip.body} typeStyle={selected ? 'dark' : 'light'} />
        )}
      </label>
    </div>
  )
}
