import { User } from '@features/auth/auth-client-adapter'
import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { ScopedFolderMembership, STLoggedOutFolder } from '@st/sdk'
import { isNotEmpty } from '@st/util/json-value'
import { match, P } from 'ts-pattern'

type AuthState =
  | { status: 'unknown' }
  | { status: 'loggedIn'; user: User }
  | { status: 'loggedOut' }

/**
 * Things are still loading.
 * Until we know the auth state and the loggedOutFolder itself, we don't know which of these possibilties we are in:
 * - Logged out
 * - Logged in and authorized
 * - Logged in and unauthorized
 */
export type STQuestionnaireLoginStateLoading = {
  status: 'loading'
  folderId: string
  authState: AuthState
  loggedOutFolder: STLoggedOutFolder | undefined
}

export type STQuestionnaireLoginStateNotFound = {
  status: 'notFound'
  folderId: string
  authState: AuthState
  loggedOutFolder: undefined
}

/**
 * We know the auth state and the loggedOutFolder,
 * but the user is not authorized to access the folder
 */
export type STQuestionnaireLoginStateUnauthorized = {
  status: 'unauthorized'
  folderId: string
  currentUser: User
  loggedOutFolder: STLoggedOutFolder
}

/**
 * We are at the step where the client needs to click the button to
 * send a verification email to themselves
 */
export type STQuestionnaireLoginStateSendVerificationEmail = {
  status: 'sendVerificationEmail'
  folderId: string
  loggedOutFolder: STLoggedOutFolder
  // If there is only one user in the folder, we don't need to let the client pick who they are
  // If there is more than one (for example a husband and wife), they will need to pick who they are
  selectedUserId: string | undefined
}

/**
 * The send verification email button has been clicked and we are waiting for the API call to complete
 */
export type STQuestionnaireLoginStateSendingVerificationEmail = {
  status: 'sendingVerificationEmail'
  folderId: string
  loggedOutFolder: STLoggedOutFolder
  selectedUserId: string
}

export type STQuestionnaireLoginStateClickEmailVerificationLink = {
  status: 'clickEmailVerificationLink'
  folderId: string
  loggedOutFolder: STLoggedOutFolder
  selectedUserId: string
}

export type STQuestionnaireLoginStateVerificationSuccessful = {
  status: 'verificationSuccessful'
  folderId: string
  currentUser: User
  loggedOutFolder: STLoggedOutFolder
  selectedUserId: string
}

export type STQuestionnaireLoginStateAuthorized = {
  status: 'authorized'
  folderId: string
  currentUser: User
  loggedOutFolder: STLoggedOutFolder
}

export type STQuestionnaireLoginState =
  | STQuestionnaireLoginStateLoading
  | STQuestionnaireLoginStateNotFound
  | STQuestionnaireLoginStateUnauthorized
  | STQuestionnaireLoginStateSendVerificationEmail
  | STQuestionnaireLoginStateSendingVerificationEmail
  | STQuestionnaireLoginStateClickEmailVerificationLink
  | STQuestionnaireLoginStateVerificationSuccessful
  | STQuestionnaireLoginStateAuthorized

export type STQuestionnaireLoginMessage =
  /**
   * Send a verification email to the the selected recipient
   */
  | {
      type: 'clickSendVerificationEmail'
      /**
       * The url to redirect to once the verification link in the mail is clicked
       */
      continueUrl: string
      /**
       * The user id of the recipient of the verification clickEmailVerificationLink
       * (The email will be sent to the email address of this user)
       */
      recipientUserId: string
    }
  /**
   * We just got notice that the user is logged in (most likely it was unknown before)
   */
  | { type: 'loggedIn'; user: User }
  /**
   * We just got notice that the user is logged out (most likely it was unknown before)
   */
  | { type: 'loggedOut' }
  | SDKResponse

type STQuestionnaireLoginInit = { folderId: string }

export const stQuestionnaireLoginModule = defineModule<
  STQuestionnaireLoginState,
  STQuestionnaireLoginMessage,
  STQuestionnaireLoginInit,
  { sdk: SDKMessage }
>({
  name: 'stQuestionnaireLogin',
  init: ({ folderId }) => {
    return [
      {
        status: 'loading',
        folderId: folderId,
        authState: { status: 'unknown' },
        loggedOutFolder: undefined
      },
      {
        sdk: {
          type: 'request',
          request: { type: 'folders/getLoggedOutFolderState', folderId }
        }
      }
    ]
  },
  handle: (state, message) => {
    return (
      match<
        STQuestionnaireLoginState,
        STQuestionnaireLoginState | [STQuestionnaireLoginState, { sdk: SDKMessage }]
      >(state)
        .with({ status: 'loading' }, (s) => {
          // We are waiting on the auth state to become resolved to loggedIn/loggedOut
          // and for the folder to successfully get fetched
          const newState = match(message)
            .with({ type: 'loggedIn' }, (m) => {
              return {
                ...s,
                authState: { status: 'loggedIn', user: m.user }
              } satisfies STQuestionnaireLoginStateLoading
            })
            .with({ type: 'loggedOut' }, (m) => {
              return {
                ...s,
                authState: { status: 'loggedOut' }
              } satisfies STQuestionnaireLoginStateLoading
            })
            .with(
              { type: 'response', operation: { type: 'folders/getLoggedOutFolderState' } },
              ({ operation }) => {
                if (operation.response) {
                  if (operation.response.deleted) {
                    return {
                      status: 'notFound',
                      folderId: s.folderId,
                      authState: s.authState,
                      loggedOutFolder: undefined
                    } satisfies STQuestionnaireLoginStateNotFound
                  }
                  return { ...s, loggedOutFolder: operation.response }
                } else {
                  return {
                    status: 'notFound',
                    folderId: s.folderId,
                    authState: s.authState,
                    loggedOutFolder: undefined
                  } satisfies STQuestionnaireLoginStateNotFound
                }
              }
            )
            .otherwise(() => s)
          return (
            match(newState)
              // if you're logged out for sure, either (1) you have access or (2) you don't have access
              // we transition accordingly
              .with({ authState: { status: 'loggedIn' }, loggedOutFolder: P.nonNullable }, (s) => {
                if (isAuthorized(s.authState.user.id, s.loggedOutFolder.memberships)) {
                  return {
                    status: 'authorized',
                    folderId: s.folderId,
                    loggedOutFolder: s.loggedOutFolder,
                    currentUser: s.authState.user
                  } satisfies STQuestionnaireLoginState
                } else {
                  return {
                    status: 'unauthorized',
                    folderId: s.folderId,
                    loggedOutFolder: s.loggedOutFolder,
                    currentUser: s.authState.user
                  } satisfies STQuestionnaireLoginState
                }
              })
              // if you're logged out for sure and we've loaded the logged out folder,
              // then we show the main screen to click the verification link
              .with({ authState: { status: 'loggedOut' }, loggedOutFolder: P.nonNullable }, (s) => {
                return {
                  status: 'sendVerificationEmail',
                  folderId: s.folderId,
                  loggedOutFolder: s.loggedOutFolder,
                  // We don't know who to send the email to yet
                  // Frontend component is responsible for passing this when the button is clicked
                  selectedUserId: undefined
                } satisfies STQuestionnaireLoginState
              })
              .otherwise((s) => s)
          )
        })
        .with({ status: 'notFound' }, (s) => {
          return {
            status: 'notFound',
            folderId: s.folderId,
            authState: s.authState,
            loggedOutFolder: undefined
          } satisfies STQuestionnaireLoginStateNotFound
        })
        .with({ status: 'unauthorized' }, (s) => {
          switch (message.type) {
            case 'loggedOut':
              return {
                status: 'sendVerificationEmail',
                folderId: state.folderId,
                loggedOutFolder: s.loggedOutFolder,
                selectedUserId: undefined
              }
            // We are already unauthorized and loggedIn, so do nothing
            case 'loggedIn':
              return state
            default:
              throw `invalid transition ${state.status} -> ${message.type}`
          }
        })
        .with({ status: 'sendVerificationEmail' }, (s) => {
          return match(message)
            .with({ type: 'loggedIn' }, (m) => {
              if (isAuthorized(m.user.id, s.loggedOutFolder.memberships)) {
                return {
                  status: 'authorized',
                  folderId: s.folderId,
                  loggedOutFolder: s.loggedOutFolder,
                  currentUser: m.user
                } satisfies STQuestionnaireLoginState
              } else {
                return {
                  status: 'unauthorized',
                  folderId: s.folderId,
                  loggedOutFolder: s.loggedOutFolder,
                  currentUser: m.user
                } satisfies STQuestionnaireLoginState
              }
            })
            .with({ type: 'clickSendVerificationEmail' }, (m) => {
              return [
                {
                  status: 'sendingVerificationEmail',
                  folderId: s.folderId,
                  selectedUserId: m.recipientUserId,
                  loggedOutFolder: s.loggedOutFolder
                } satisfies STQuestionnaireLoginStateSendingVerificationEmail,
                {
                  sdk: {
                    type: 'request',
                    request: {
                      type: 'folders/sendFolderEmailVerificationLink',
                      folderId: s.folderId,
                      userId: m.recipientUserId,
                      continueUrl: m.continueUrl
                    }
                  } satisfies SDKMessage
                }
              ] satisfies [STQuestionnaireLoginStateSendingVerificationEmail, { sdk: SDKMessage }]
            })
            .otherwise(() => state)
        })
        // the intermedaite state when we are sending a verification email
        .with({ status: 'sendingVerificationEmail' }, (s) => {
          return (
            match(message)
              // the api call to send the email verification was successful
              // this means the email was sent, so now they need to open their email and click
              // the link to verify their email
              .with(
                {
                  type: 'response',
                  operation: { type: 'folders/sendFolderEmailVerificationLink' }
                },
                ({ operation }) => {
                  return {
                    status: 'clickEmailVerificationLink',
                    folderId: s.folderId,
                    loggedOutFolder: s.loggedOutFolder,
                    selectedUserId: s.selectedUserId
                  } satisfies STQuestionnaireLoginStateClickEmailVerificationLink
                }
              )
              .otherwise(() => s)
          )
        })
        .with({ status: 'clickEmailVerificationLink' }, (s) => {
          // we are on the screen where they need to click the email verification link
          // once they do that, they will automatically get logged in
          // then we know we can transition to the verification successful
          // we don't want to transition to authorized to avoid having 2 tabs open with the logged in questionnaire
          return match(message)
            .with({ type: 'loggedIn' }, ({ user }) => {
              return {
                status: 'verificationSuccessful',
                folderId: s.folderId,
                currentUser: user,
                loggedOutFolder: s.loggedOutFolder,
                selectedUserId: s.selectedUserId
              } satisfies STQuestionnaireLoginStateVerificationSuccessful
            })
            .otherwise(() => s)
        })
        // authorized means you're basically logged in and authorized
        // for example, you are actually seeing the questionnaire
        .with({ status: 'authorized' }, (s) => {
          return (
            match(message)
              // if they get logged out, we go back to the login screen
              .with({ type: 'loggedOut' }, () => {
                return [
                  {
                    status: 'loading',
                    folderId: s.folderId,
                    authState: { status: 'loggedOut' },
                    loggedOutFolder: undefined
                  },
                  {
                    sdk: {
                      type: 'request',
                      request: { type: 'folders/getLoggedOutFolderState', folderId: s.folderId }
                    }
                  }
                ] satisfies [STQuestionnaireLoginStateLoading, { sdk: SDKMessage }]
              })
              .otherwise(() => s)
          )
        })
        // once a verification is successful, we have nothing more to do
        // user can close the tab and nothing more
        .with({ status: 'verificationSuccessful' }, () => state)
        .exhaustive()
    )
  }
})

function isAuthorized(currentUserId: string, memberships: ScopedFolderMembership[]): boolean {
  const membership = memberships.find((m) => m.user.id == currentUserId)
  return membership != undefined
}

export function selPossibleUsers(state: STQuestionnaireLoginState) {
  if (!state.loggedOutFolder) {
    return []
  }
  return state.loggedOutFolder.memberships
    .map((m) => {
      return state.loggedOutFolder!.users.find((u) => u.id == m.user.id)
    })
    .filter(isNotEmpty)
}

export function selLastSentTo(state: STQuestionnaireLoginState) {
  return 'selectedUserId' in state
    ? state.loggedOutFolder!.users.find((u) => u.id == state.selectedUserId)
    : undefined
}
