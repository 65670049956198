type WithoutValues<T, V> = {
  [P in keyof T]: Exclude<T[P], V>
}

/**
 * Removes all properties from an object that have a specific value.
 * @param obj - The object to remove properties from.
 * @param value - The value to remove properties with.
 * @returns A new object with all properties that have the specified value removed.
 *
 * @example
 * const obj = { a: 1, b: 2, c: 1 };
 * const newObj = removeValues(obj, 1);
 * // newObj is { b: 2 }
 */
export function removeValues<T extends Record<string, any>, V>(
  obj: T,
  value: V
): WithoutValues<T, V> {
  const newObj: WithoutValues<T, V> = { ...obj }
  for (var k of Object.keys(newObj)) {
    if (newObj[k] === value) {
      delete newObj[k]
    }
  }
  return newObj
}

export function removeKey<K extends keyof T, T>(obj: T, key: K): Omit<T, K> {
  let copy = { ...obj }
  delete copy[key]
  return copy
}
