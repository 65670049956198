import { ComponentConfig, OptionConfig } from './ui-config'

export * from './ui-config'

export type SectionID = string

export type ComponentConfigType = ComponentConfig['__typename'] & string

export const NONE_OPTION: OptionConfig = {
  key: 'none',
  label: 'None of the above'
}

export const UNCATEGORIZED_DOCUMENT_TYPE_ID = 'uncategorized'

/**
 * One of the categories in a questionnaire (for income/deductions) that can be selected by the user
 */
export type QuestionnaireCategoryOption = {
  /**
   * The section it is part of (e.g. 'income', 'deductions')
   */
  sectionId: string

  /**
   * The label of the category
   */
  label: string

  /**
   * The user input key that will be used to store whether this category is selected
   */
  selectedUserInputKey: string
}

export function categorySelectInput(categoryId: string) {
  return `${categoryId}.did_confirm`
}
