import { UploadDocumentIcon } from '@components/icons'
import { useTrackEvent } from '@features/analytics'
import { FileDropZone } from '@features/file-storage'
import { useFileStorageAdapter } from '@features/file-storage/file-storage-provider'
import {
  EditOrganizationAttributes,
  stOrganizationModule,
  updateOrganization
} from '@features/st-organizations/st-organization-module'
import { OrganizationSettingsScaffold } from '@features/st-organizations/st-organization-settings-scaffold'
import { useFlashState } from '@st/react-util/use-flash-state'
import { useProcess, useProcessState } from '@st/redux'
import { Button, TextInput } from '@st/theme'
import { extname } from '@st/util'
import { cuid } from '@st/util/cuid'
import { isEmpty } from '@st/util/json-value'
import { useState } from 'react'

/**
 * Page that lets an accountant personalize their organization.
 * For example setting a logo, changing their name, etc.
 *
 * The clients of an accountant will see this in the whitelabel app
 * when logging in and when filling out a questionnaire
 */
export function OrganizationBrandingPage() {
  useTrackEvent('viewed_branding_page')

  const stOrganization = useProcess(stOrganizationModule)
  const organization = useProcessState(stOrganizationModule, (s) => s.state!.organization)

  const [modifications, setModifications] = useState<EditOrganizationAttributes>({
    name: organization.name,
    logo: organization.logo
  })
  const [hasSaved, setHasSaved] = useFlashState(false, 2000)

  const formValues = { ...organization, ...modifications }

  const orgNameErrorMessage = isEmpty(formValues.name) ? 'A company name is required' : null

  async function onClickSave() {
    if (hasSaved) {
      return
    }
    setHasSaved(true)

    stOrganization.send(updateOrganization(modifications))
  }

  function onChangeField(field: keyof EditOrganizationAttributes, value: string) {
    setModifications({ ...modifications, [field]: value })
  }

  return (
    <OrganizationSettingsScaffold title="Settings">
      <h2 className="mb-5 text-xl text-gray-800">Branding</h2>

      <div className="flex w-[400px] flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label className="text-base text-gray-900">Company name</label>
          <TextInput
            className="condensed"
            placeholder="Company name"
            type="text"
            value={formValues.name || ''}
            onChange={(value) => onChangeField('name', value)}
          />

          {orgNameErrorMessage ? (
            <span className="text-base text-red-600">{orgNameErrorMessage}</span>
          ) : null}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base text-gray-900">Reply-to email</label>
          <TextInput
            className="condensed"
            placeholder="Reply-to email"
            type="text"
            value={formValues.email || ''}
            onChange={(value) => onChangeField('email', value)}
          />

          {orgNameErrorMessage ? (
            <span className="text-base text-red-600">{orgNameErrorMessage}</span>
          ) : null}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base text-gray-900">Logo</label>
          <BrandingImagePicker
            destinationFolder={`organizations/${organization.id}/public`}
            filepath={formValues.logo || undefined}
            onChange={(value) => onChangeField('logo', value)}
          />
        </div>

        <Button variant="primary" className="self-end" onClick={onClickSave}>
          {hasSaved ? 'Saved!' : 'Save changes'}
        </Button>
      </div>
    </OrganizationSettingsScaffold>
  )
}

export function BrandingImagePicker({
  filepath,
  onChange,
  destinationFolder
}: {
  destinationFolder: string
  filepath?: string
  onChange?: (url: string) => void
}) {
  const storage = useFileStorageAdapter()

  async function onDrop(dt: DataTransfer) {
    const file = dt.files.item(0)
    if (!file) return

    const filename = cuid() + extname(file.name)
    const destinationFilePath = `${destinationFolder}/${filename}`

    await storage.upload(file, { destinationPath: destinationFilePath })
    onChange?.(destinationFilePath)
  }

  return (
    <FileDropZone className="h-32 w-32" onDrop={onDrop} accept="image/*">
      {filepath ? (
        <img src={storage.resolvePublicURL(filepath)} alt="Logo preview" />
      ) : (
        <UploadDocumentIcon />
      )}
    </FileDropZone>
  )
}
