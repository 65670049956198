import { useAppDeps } from '@features/app-deps-provider'
import {
  documentUploadsModule,
  selUploads,
  Upload,
  uploadDocument
} from '@features/document-uploads'
import { useProcess, useProcessState } from '@st/redux'
import { ScopedFolderMembership, STDocument, STOpenFolderState } from '@st/sdk'
import {
  ArrowRightIcon,
  Button,
  ContextMenu,
  ContextMenuItem,
  Dialog,
  DialogButtons,
  DocumentTreeView,
  DocumentTreeViewItem,
  DocumentTreeViewSection,
  FileUploadItem,
  MiniDocumentDropZone,
  Modal,
  TextArea,
  useShowSnackbar,
  WarningIcon
} from '@st/theme'
import { TrashIcon } from '@st/theme/src/icons/12x12'
import { ArrowDownIcon } from '@st/theme/src/icons/16x16'
import { cuid } from '@st/util/cuid'
import { Progress } from '@st/util/progress'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { downloadSourceDocument } from './st-download-document-handler'
import {
  getBookmarkSections,
  hasUploadedDocuments,
  selDocumentTooltip,
  selSelectedDoc,
  stFolderModule
} from './st-folder-module'
import { STMoveDocumentDialog } from './st-move-document-dialog'
import { RadioSelect } from '../ui-components'
import { isEmpty } from '@st/util/json-value'

type DocumentAction =
  | { type: 'markPrepared'; doc: STDocument }
  | { type: 'markReviewed'; doc: STDocument }
  | { type: 'delete'; doc: STDocument }
  | { type: 'download'; doc: STDocument }
  | { type: 'move'; doc: STDocument }
  | { type: 'reportIssue'; doc: STDocument }

export function STBookmarksSidebar() {
  const { sdk } = useAppDeps()
  const stFolder = useProcess(stFolderModule)
  const documentUploads = useProcess(documentUploadsModule)

  const uploads = useProcessState(documentUploadsModule, selUploads)
  const folderState = useProcessState(stFolderModule, (s) => s.folderState)
  const selectedDoc = useProcessState(stFolderModule, selSelectedDoc)

  const [documentAction, setDocumentAction] = useState<DocumentAction | undefined>()

  function onDropFiles(dt: DataTransfer) {
    for (const file of dt.files) {
      documentUploads.send(
        uploadDocument({
          uploadId: cuid(),
          file,
          folderId: folderState!.folderId
        })
      )
    }
  }

  if (!folderState) {
    return null
  }

  return (
    <>
      <FolderBookmarkSidebar
        state={folderState}
        uploads={uploads}
        selectedDocId={selectedDoc?.id}
        onDropFiles={onDropFiles}
        onSelect={(doc) => stFolder.send({ type: 'docSelected', docId: doc.id })}
        onClearUpload={(uploadId) => documentUploads.send({ type: 'clearUpload', uploadId })}
        onDocumentAction={(action) => {
          switch (action.type) {
            case 'delete':
            case 'move':
            case 'reportIssue':
              setDocumentAction(action)
              break
            case 'download':
              stFolder.send(downloadSourceDocument(action.doc))
              break
          }
        }}
      />
      {match(documentAction)
        .with({ type: 'move' }, ({ doc }) => {
          return (
            <Modal isOpen={true}>
              <STMoveDocumentDialog
                folderId={folderState.folderId}
                doc={doc}
                onClose={() => setDocumentAction(undefined)}
              />
            </Modal>
          )
        })
        .with({ type: 'delete' }, ({ doc }) => {
          return (
            <Modal isOpen={true}>
              <DeleteDocumentDialog
                folderId={folderState.folderId}
                doc={doc}
                onClose={() => setDocumentAction(undefined)}
              />
            </Modal>
          )
        })
        .with({ type: 'reportIssue' }, ({ doc }) => {
          return (
            <Modal isOpen={true}>
              <ReportDocumentIssueDialog
                folderId={folderState.folderId}
                doc={doc}
                onClose={() => setDocumentAction(undefined)}
              />
            </Modal>
          )
        })
        .otherwise(() => null)}
    </>
  )
}

type FolderBookmarkSidebarProps = {
  state: STOpenFolderState
  uploads: Upload[]
  selectedDocId: string | undefined
  onDropFiles: (dt: DataTransfer) => void
  onSelect?: (doc: STDocument) => void
  onClearUpload?: (uploadId: string) => void
  onDocumentAction?: (action: DocumentAction) => void
}
function FolderBookmarkSidebar({
  state,
  uploads,
  selectedDocId,
  onDropFiles,
  onSelect,
  onClearUpload,
  onDocumentAction
}: FolderBookmarkSidebarProps) {
  const sections = getBookmarkSections(state)
  const noDocumentsUploaded = !hasUploadedDocuments(state.documents)

  return (
    <DocumentTreeView
      bottom={
        <div className="flex flex-col gap-2">
          {uploads.map((upload) => {
            return (
              <FileUploadItem
                key={upload.id}
                value={Progress.toNumber(upload.progress)}
                status={upload.status}
                onClose={() => onClearUpload?.(upload.id)}
              >
                {upload.filename}
              </FileUploadItem>
            )
          })}

          <MiniDocumentDropZone caption="Documents up to 100MB" onDrop={onDropFiles} />
        </div>
      }
    >
      {sections.map((section) => {
        return (
          <DocumentTreeViewSection key={section.heading.id} title={section.heading.name}>
            {section.items.map((doc) => {
              return (
                <STDocumentTreeViewItem
                  key={doc.id}
                  doc={doc}
                  onClick={() => onSelect?.(doc)}
                  onDocumentAction={onDocumentAction}
                  selected={selectedDocId == doc.id}
                  memberships={state.memberships}
                />
              )
            })}
          </DocumentTreeViewSection>
        )
      })}
      {noDocumentsUploaded && (
        <DocumentTreeViewSection title="Uploaded documents">
          <DocumentTreeViewItem>
            <div className="text-sm text-gray-500">No documents uploaded</div>
          </DocumentTreeViewItem>
        </DocumentTreeViewSection>
      )}
    </DocumentTreeView>
  )
}

function STDocumentTreeViewItem({
  doc,
  onDocumentAction,
  onClick,
  selected,
  memberships
}: {
  doc: STDocument
  memberships: ScopedFolderMembership[]
  onDocumentAction?: (action: DocumentAction) => void
  onClick?: (doc: STDocument) => void
  selected?: boolean
}) {
  return (
    <DocumentTreeViewItem
      tip={selDocumentTooltip(doc, { memberships })}
      menu={
        <ContextMenu>
          {doc.permissions.includes('download') && (
            <ContextMenuItem
              icon={<ArrowDownIcon />}
              onClick={() => onDocumentAction?.({ type: 'download', doc })}
            >
              Download
            </ContextMenuItem>
          )}

          {doc.permissions.includes('move') && (
            <ContextMenuItem
              icon={<ArrowRightIcon />}
              onClick={() => onDocumentAction?.({ type: 'move', doc })}
            >
              Move to...
            </ContextMenuItem>
          )}

          {doc.permissions.includes('report_issue') && (
            <ContextMenuItem
              icon={<WarningIcon className="size-3.5" />}
              onClick={() => onDocumentAction?.({ type: 'reportIssue', doc })}
            >
              Report issue
            </ContextMenuItem>
          )}

          {doc.permissions.includes('delete') && (
            <ContextMenuItem
              icon={<TrashIcon />}
              onClick={() => onDocumentAction?.({ type: 'delete', doc })}
            >
              Delete
            </ContextMenuItem>
          )}
        </ContextMenu>
      }
      selected={selected}
      onClick={() => onClick?.(doc)}
    >
      {doc.name}
    </DocumentTreeViewItem>
  )
}

function DeleteDocumentDialog({
  folderId,
  doc,
  onClose
}: {
  folderId: string
  doc: STDocument
  onClose: () => void
}) {
  const { sdk } = useAppDeps()
  const showSnackbar = useShowSnackbar()

  return (
    <Dialog
      title="Confirm delete"
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              sdk.send({
                type: 'folders/deleteDocument',
                folderId: folderId,
                documentId: doc.id
              })
              showSnackbar(`Deleted document ${doc.name}`)
              onClose?.()
            }}
          >
            Delete
          </Button>
        </DialogButtons>
      }
    >
      <p>Please confirm whether you would like to delete {doc.name}</p>
    </Dialog>
  )
}

function ReportDocumentIssueDialog({
  folderId,
  doc,
  onClose
}: {
  folderId: string
  doc: STDocument
  onClose: () => void
}) {
  const { sdk } = useAppDeps()
  const showSnackbar = useShowSnackbar()

  const [issueType, setIssueType] = useState<string | undefined>()
  const [issueDescription, setIssueDescription] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  async function onClickReport() {
    setIsSubmitting(true)
    await sdk.send({
      type: 'folders/reportDocumentIssue',
      folderId: folderId,
      documentId: doc.id,
      issueType: issueType as any,
      issueDescription: issueDescription
    })
    showSnackbar(`Reported issue for document ${doc.name}`)
    onClose?.()
  }

  return (
    <Dialog
      title="Report document issue"
      subtitle="If something didn't work as expected, please let us know. We use this information to improve our algorithms and release new updates every week."
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!issueType || isSubmitting} onClick={onClickReport}>
            {isSubmitting ? 'Reporting...' : 'Report'}
          </Button>
        </DialogButtons>
      }
    >
      <RadioSelect
        options={[
          { label: 'Document is not viewable', key: 'not_viewable' },
          { label: 'Document was miscategorized', key: 'miscategorized' },
          {
            label: 'Document did not auto-complete the checklist item',
            key: 'missed_checklist_item'
          },
          { label: 'Other', key: 'other' }
        ]}
        value={issueType}
        onChange={(value) => {
          setIssueType(value)
        }}
      />

      <TextArea
        className="mt-2"
        placeholder="If there is more context to share, please elaborate here."
        value={issueDescription}
        onChange={setIssueDescription}
      />
    </Dialog>
  )
}
