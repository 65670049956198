import { QuestionTip } from '@st/theme'
import { cn } from '@util/cn'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { NoteTip } from './note-tip'

type Props = {
  children?: ReactNode
  tip?: { title?: string; body?: string } | null
  caption?: string
  noteTip?: string
  selected?: boolean
  onClick?: () => void
}
export function MultiSelectOption({ selected, children, caption, tip, noteTip, onClick }: Props) {
  return (
    <div
      className={clsx(
        // 'multi-select-option',
        'h-16 cursor-pointer rounded-lg px-5 font-bold outline outline-1 outline-gray-200',
        'hover:outline-2 hover:outline-blue-500',
        // selected ? 'multi-select-option--selected' : null,
        'flex flex-row items-center',
        {
          'bg-gradient-to-tr from-[#526bf0] to-[#8572ff] text-white hover:outline-none': selected
        }
      )}
      onClick={() => onClick?.()}
    >
      <label className="ml-2 cursor-pointer text-lg font-bold">{children}</label>
      {tip ? <span>&nbsp;</span> : null}
      {tip && (
        <QuestionTip title={tip.title} body={tip.body} typeStyle={selected ? 'dark' : 'light'} />
      )}

      <span
        className={clsx(
          'grow text-right text-base font-light',
          selected ? 'text-white' : 'text-gray-500'
        )}
      >
        {caption}
      </span>

      {noteTip ? <NoteTip translateX={20}>{noteTip}</NoteTip> : null}
    </div>
  )
}
