import { devtools } from 'zustand/middleware'
import { UseBoundStoreWithEqualityFn, createWithEqualityFn } from 'zustand/traditional'

import { StoreApi } from 'zustand'
import { deepEqual } from './deep-equal'
import {
  Command,
  DepsMap,
  MessageContext,
  ProcessMessage,
  ProcessModule,
  ProcessName,
  Ref,
  RefObject,
  Send,
  SendOpts
} from './process'

type DispatchEvent<State, Event> = (event: Event, context: EventContext<State>) => void

type EventContext<State> = { time: string; prevState: State; nextState: State }

type ReduxStoreState<State, Event> = {
  history: Event[]
  state: State
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: boolean
    __reduxStoreStates: Record<string, ReduxStoreState<any, any> | undefined>
  }
}

type Process = {
  /**
   * Name of the process. Sending messages to a process refers to the process.
   */
  name: string

  /**
   * A stateless set of handlers that define the processes
   * (The state is managed by the store)
   */
  module: ProcessModule<any, any, any, any, any>

  /**
   * The arg the init handler will be called with
   */
  initArg: any

  /**
   * For stateful processes that need to hold onto dirty things in their state
   * such as event handlers, we generate references like "$ref:5" which can be resolved
   * to their actual values.
   *
   * This way we can continue to store value types in the state without introducing.
   *
   */
  refs: Record<string, RefEntry<any>>

  /**
   * The number of others who need this process. When it reaches 0, the process gets disposed.
   * **NOT TO BE CONFUSED** with {@link Process.refs} which is used to hold references to opaque
   * objects such as event handlers, libraries, etc.
   *
   * For require/release we keep track of ref counts so that when
   * no more people are using it, we know to dispose the process
   */
  refCount: number
}

type RefEntry<T> = {
  /**
   * The object (for example an event handler or a 3rd party library reference)
   */
  obj: T

  /**
   * A handler that will dispose the object when a process is disposed.
   */
  dispose: (obj: T) => void
}

type ReduxStoreConstructor = {
  name: string
  onEvent?: DispatchEvent<any, any>
  modules?: ProcessModule<any, any, any, any>[]
  onError?: (error: any) => void
}
export class ReduxStore implements Disposable {
  private storeName: string
  private useAppStore: UseBoundStoreWithEqualityFn<StoreApi<any>>

  /**
   * A map of [processName] => and the live processes.
   */
  private processes: Record<ProcessName, Process> = {}

  /**
   * A way to subscribe to the global store.
   *
   * Will later be replaced by a way to listen to trace specific processes.
   */
  private subscribeListeners: DispatchEvent<any, Event>[] = []

  private onError: (error: any) => void

  constructor({ name, onEvent, onError, modules = [] }: ReduxStoreConstructor) {
    this.storeName = name

    const devtoolsEnabled =
      typeof window === 'undefined' ? false : Boolean(window.__REDUX_DEVTOOLS_EXTENSION__)

    const persitedState =
      typeof window === 'undefined' ? undefined : window.__reduxStoreStates?.[name]

    this.useAppStore = createWithEqualityFn()(
      devtools(
        (...args) => {
          return persitedState?.state ?? {}
        },
        {
          name: name,
          enabled: devtoolsEnabled
        }
      ),
      deepEqual
    )

    for (const mod of modules) {
      this._initProcess(mod.name, mod, undefined)
    }

    if (onEvent) {
      this.subscribe(onEvent)
    }

    this.onError = onError ?? (() => {})
  }

  getState = () => this.useAppStore.getState()

  private _resolveProcess = (processName: string, message?: any) => {
    const process = this.processes[processName]
    if (!process) {
      throw new ProcessMissingException({ processName, message })
    }

    return process
  }

  _initProcess = <
    State,
    Event extends ProcessMessage,
    InitArg = undefined,
    Deps extends DepsMap = {}
  >(
    processName: string,
    module: ProcessModule<State, Event, InitArg, any, Deps>,
    initArg?: InitArg
  ) => {
    if (processName in this.processes) {
      throw new Error(`process ${processName} already running`)
    }

    this.processes[processName] = {
      name: processName,
      module,
      initArg: initArg,
      refs: {},
      refCount: 1
    }
    this.send(processName, { type: 'init' })
  }

  _disposeProcess = (processName: string) => {
    const process = this._resolveProcess(processName)

    this.send(process.name, { type: `dispose` })

    for (const [refId, refEntry] of Object.entries(process.refs)) {
      refEntry.dispose(refEntry.obj)
    }

    delete this.processes[process.name]
  }

  _createRef = <T extends RefObject>(
    processName: string,
    obj: T,
    dispose: (obj: T) => void
  ): Ref<T> => {
    const process = this._resolveProcess(processName)

    // we'll basically auto-increment the refId
    const currentRefCount = Object.keys(process.refs).length
    const ref = `$ref:${currentRefCount + 1}`

    process.refs[ref] = { obj, dispose }

    return ref as Ref<T>
  }

  _resolveRef = <T extends RefObject>(processName: string, ref: Ref<T>) => {
    return this._resolveProcess(processName).refs[ref].obj
  }

  requireProcess = <
    State,
    Event extends ProcessMessage,
    InitArg = undefined,
    Deps extends DepsMap = {}
  >(
    processName: string,
    module: ProcessModule<State, Event, InitArg, any, Deps>,
    initArg?: InitArg
  ) => {
    const process = this.processes[processName]

    // no need to init the module - it's already registered
    if (process) {
      process.refCount++
    } else {
      this._initProcess(processName, module, initArg)
    }
  }

  releaseProcess = (processName: string) => {
    const process = this._resolveProcess(processName)
    process.refCount--

    if (process.refCount == 0) {
      this._disposeProcess(process.name)
    }
  }

  send: Send = <E extends ProcessMessage>(
    processName: string,
    message: E | E[] | Command<any, E, any, any>,
    opts: SendOpts = {}
  ) => {
    if (!this.processes[processName]) {
      console.warn(`Process ${processName} does not exist`)
      return
    }

    const time = new Date().toISOString()
    const context = opts.context ?? {}
    const process = this._resolveProcess(processName, message)

    if (!process) {
      throw new ProcessMissingException({ processName, message })
    }

    if (typeof message == 'function') {
      const command = message
      return command(
        {
          getState: () => {
            const storeState = this.getState() as any
            return storeState[processName]
          },
          send: (message: any) => {
            return this.send(processName, message, opts)
          }
        },
        context
      )
    }

    const messages = Array.isArray(message) ? message : [message]

    const messageContext: MessageContext = {
      from: opts.from,
      send: (processName: string, message: any, opts?: SendOpts) =>
        this.send(processName, message, opts),
      createRef: <T extends RefObject>(obj: T, dispose: (obj: T) => void) =>
        this._createRef(processName, obj, dispose),
      resolveRef: <T extends RefObject>(ref: Ref<T>) => this._resolveRef(processName, ref)
    }

    for (const msg of messages) {
      const curStoreState = this.useAppStore.getState()
      const curProcessState = (curStoreState as any)[processName]

      const [nextProcessState, sendMessages] = callMessageHandler(
        process,
        msg,
        curProcessState,
        curStoreState,
        messageContext,
        this.onError
      )

      const nextStoreState = {
        ...this.useAppStore.getState(),
        [processName]: nextProcessState
      }
      // undefined is equivalent to saying delete the state
      if (nextProcessState === undefined) {
        delete nextStoreState[processName]
      }
      const namespacedMessage: any = {
        ...msg,
        type: `${processName}/${msg.type}`
      }
      if (opts.from) {
        namespacedMessage.from = opts.from
      }
      ;(this.useAppStore.setState as any)(
        nextStoreState,
        true,
        // we namespace the message so it shows up in redux like for ex "processName/msg" router/updated
        namespacedMessage
      )

      // deprecated - notify all listeners
      // of the global state change of this message
      // should be replaced with a granular way
      // to listen to a particular process for messages
      this.onEvent(namespacedMessage as any, {
        prevState: curStoreState,
        nextState: nextStoreState,
        time
      })

      if (sendMessages) {
        validateSendMap(sendMessages, processName)

        for (const [destProcessName, destMessages] of Object.entries(sendMessages)) {
          for (const destMsg of destMessages) {
            this.send(
              // self is a special reference to the current process
              destProcessName == 'self' ? processName : destProcessName,
              destMsg,
              {
                context,
                from: processName,
                send: this.send
              }
            )
          }
        }
      }
    }
  }

  private HISTORY_SIZE = 100
  private eventHistory: Event[] = []

  onEvent = (event: Event, context: EventContext<any>) => {
    const time = new Date().toISOString()
    this.eventHistory = [...this.eventHistory, { ...event, time }].slice(-this.HISTORY_SIZE)

    if (typeof window !== 'undefined') {
      window.__reduxStoreStates ??= {}
      window.__reduxStoreStates[this.storeName] = {
        history: this.eventHistory,
        state: context.nextState
      }
    }

    // notify all listeners of the dispatch
    for (var i = 0; i < this.subscribeListeners.length; i++) {
      this.subscribeListeners[i](event, context)
    }
  }

  /**
   * @deprecated
   *
   * Will be replaced with a way to trace specific processes (kind of like OTP observer)
   *
   * Subscribe to when the state of the store changes (in response to an event being dispatched)
   *
   * @param listener
   * @returns A function to unsubscribe
   */
  subscribe = (listener: DispatchEvent<any, Event>) => {
    this.subscribeListeners.push(listener)

    return () => {
      this.subscribeListeners.splice(this.subscribeListeners.indexOf(listener), 1)
    }
  }

  /**
   * Can subscribe to the state of a process using useProcessState instead
   *
   * React hook to get a derived state based on the state of the global store
   */
  useSelect = <T>(sel: (s: any) => T): T => {
    return this.useAppStore(sel)
  };

  [Symbol.dispose](): void {
    this.subscribeListeners = []
  }
}

type ProcessMissingExceptionContext = {
  processName: string
  message?: any
}
export class ProcessMissingException extends Error {
  processName: string
  messageDetails: string

  constructor(extra: ProcessMissingExceptionContext) {
    super(
      `Process ${JSON.stringify(extra.processName)} is not running.` +
        (extra.message ? `Tried to send message: ${JSON.stringify(extra.message)}` : '')
    )
    this.name = 'ProcessMissingException'
    this.processName = extra.processName
    this.messageDetails = JSON.stringify(extra.message)

    Object.setPrototypeOf(this, ProcessMissingException.prototype)
  }
}

/**
 * When calling a process with {@link ReduxStore.send},
 * we ultimately invoke handlers on modules defined with defineModule(...)
 *
 * This helper helps call out to the right function depending on the message type.
 * The init and dispose messages are special types while the rest are passed through `handle(state, message)`
 *
 * @param registeredProcess
 * @param message
 *  The message itself
 * @param curProcessState
 *  The current state of the process (modules are just stateless functions)
 * @param messageContext
 *  Relevant functions the process handlers can themsleves use such as send
 * @returns
 *  A tuple with the next state of the process and an optional map of messages to send out
 *  to other processes.
 */
function callMessageHandler<M extends ProcessMessage>(
  registeredProcess: Process,
  message: M,
  curProcessState: any,
  curStoreState: any,
  messageContext: MessageContext,
  onError: (error: any) => void
): [any, Record<string, ProcessMessage[]>] {
  const module = registeredProcess.module

  const depsState: Record<string, any> = {}
  if (Object.keys(registeredProcess.module.deps).length > 0) {
    for (const [processName, v] of Object.entries(registeredProcess.module.deps)) {
      depsState[processName] = curStoreState[processName]
    }
  }

  switch (message.type) {
    case 'init':
      return normalizeHandlerResp(module.init(registeredProcess.initArg, messageContext, depsState))
    case 'dispose':
      if (!module.dispose) {
        return [undefined, {}]
      } else {
        const [_, sendResp] = normalizeHandlerResp(
          module.dispose(curProcessState, messageContext, depsState)
        )
        return [undefined, sendResp]
      }
    default:
      try {
        const rawHandleResp = module.handle(curProcessState, message, messageContext, depsState)
        return normalizeHandlerResp(rawHandleResp)
      } catch (e) {
        console.error(e)
        onError(e)
        return [curProcessState, {}]
      }
  }
}

function normalizeHandlerResp(msg: any): [any, Record<string, ProcessMessage[]>] {
  // it's a tuple of [state, sends]
  if (Array.isArray(msg)) {
    const [reply, sends] = msg
    return [reply, normalizeSends(sends)]
  } else {
    // it is just state
    return [msg, {}]
  }
}

function normalizeSends(sends: Record<string, any>): Record<string, ProcessMessage[]> {
  const norm: Record<string, any> = []
  for (const [k, v] of Object.entries(sends)) {
    norm[k] = Array.isArray(v) ? v : [v]
  }
  return norm
}

function validateSendMap(sendMap: Record<string, ProcessMessage[]>, from: string | undefined) {
  for (const [destProcessName, msgs] of Object.entries(sendMap)) {
    for (const msg of msgs) {
      if (!msg) {
        console.error(
          `Attempted to send an undefined/null message from process "${from}" to "${destProcessName}".`
        )
      }
    }
  }
}
