import { defineModule, defineTask } from '@st/redux'
import { GeneratedPresignedDocumentUploadURLResponse, SDKRequestNetworkError, STSDK } from '@st/sdk'
import { Progress } from '@st/util/progress'
import { asc, sort } from '@st/util/sort'
import { create } from 'mutative'

type DocumentUploadsState = {
  uploads: Record<string, Upload>
}

export type DocumentUploadContext = {
  sdk: STSDK
}

export type Upload = {
  id: string
  progress: Progress
  startedAt: string
  status: 'running' | 'success' | 'failed'

  error?: UploadError

  filesize: number
  filename: string

  folderId: string
  mimeType: string

  documentId: string | undefined
  documentTypeId?: string | undefined
  questionnaireDocumentTypeId?: string | undefined

  meta: Record<string, string>
}

type UploadError = 'empty_file' | 'network_error' | 'server_error'

export type Message =
  | { type: 'uploadStarted'; upload: Upload }
  | { type: 'uploadPrepared'; upload: Upload }
  | { type: 'uploadProgressed'; uploadId: string; progress: Progress }
  | { type: 'uploadCompleted'; uploadId: string }
  | { type: 'uploadFailed'; uploadId: string; error?: UploadError }
  | { type: 'clearUpload'; uploadId: string }

export const documentUploadsModule = defineModule<DocumentUploadsState, Message>({
  name: 'documentUploads',
  init: () => {
    return { uploads: {} }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'uploadStarted':
        return create(state, (s) => {
          s.uploads[message.upload.id] = message.upload
        })
      case 'uploadPrepared':
        return create(state, (s) => {
          s.uploads[message.upload.id] = message.upload
        })
      case 'uploadProgressed':
        return create(state, (s) => {
          if (s.uploads[message.uploadId]) {
            s.uploads[message.uploadId].progress = message.progress
          }
        })
      case 'uploadCompleted':
        return create(state, (s) => {
          if (s.uploads[message.uploadId]) {
            s.uploads[message.uploadId].status = 'success'
            delete s.uploads[message.uploadId]
          }
        })
      case 'uploadFailed':
        return create(state, (s) => {
          if (s.uploads[message.uploadId]) {
            s.uploads[message.uploadId].status = 'failed'
            s.uploads[message.uploadId].error = message.error
          }
        })
      case 'clearUpload':
        return create(state, (s) => {
          delete s.uploads[message.uploadId]
        })
    }
  }
})

export function selUploads(uploadsState: DocumentUploadsState) {
  const uploads = Object.values(uploadsState.uploads)
  return sort(uploads, asc('startedAt'))
}

export function selFailedUploads(uploadsState: DocumentUploadsState) {
  const uploads = Object.values(uploadsState.uploads)
  return sort(uploads, asc('startedAt')).filter((upload) => upload.status == 'failed')
}

export function selQuestionnaireUploadsOfDocumentType(
  uploadsState: DocumentUploadsState,
  documentTypeId: string
) {
  const uploads = Object.values(uploadsState.uploads)
  return sort(uploads, asc('startedAt')).filter(
    (upload) => upload.questionnaireDocumentTypeId == documentTypeId
  )
}

type UploadDocument = {
  uploadId: string
  file: File
  folderId: string
  documentTypeId?: string
  questionnaireDocumentTypeId?: string
  meta?: Record<string, string>
}

export const uploadDocument = defineTask(
  documentUploadsModule,
  async (
    { send },
    {
      uploadId,
      file,
      folderId,
      documentTypeId,
      questionnaireDocumentTypeId,
      meta = {}
    }: UploadDocument,
    { sdk }: DocumentUploadContext
  ) => {
    let upload: Upload = {
      id: uploadId,
      startedAt: new Date().toISOString(),
      filename: file.name,
      folderId: folderId,
      mimeType: file.type,
      filesize: file.size,
      status: 'running',
      progress: { value: 0, max: file.size },
      documentTypeId: documentTypeId,
      questionnaireDocumentTypeId: questionnaireDocumentTypeId,
      documentId: undefined,
      meta: meta
    }

    send({ type: 'uploadStarted', upload })

    if (file.size == 0) {
      send({ type: 'uploadFailed', uploadId: upload.id, error: 'empty_file' })
      return
    }

    let resp: GeneratedPresignedDocumentUploadURLResponse | undefined

    try {
      resp = await sdk.send({
        type: 'folders/generatePresignedDocumentUploadURL',
        filename: upload.filename,
        folderId: upload.folderId
      })
    } catch (error) {
      send({
        type: 'uploadFailed',
        uploadId: upload.id,
        error: error instanceof SDKRequestNetworkError ? 'network_error' : 'server_error'
      })
      return
    }

    upload = { ...upload, documentId: resp.documentId }
    send({ type: 'uploadPrepared', upload })

    console.log('Initiating upload request', {
      url: resp.uploadUrl,
      method: 'PUT',
      headers: resp.uploadHeaders,
      fileName: file.name,
      fileSize: file.size,
      mimeType: file.type
    })

    var xhr = new XMLHttpRequest()
    xhr.open('PUT', resp.uploadUrl, true)

    for (const [key, value] of Object.entries(resp.uploadHeaders)) {
      xhr.setRequestHeader(key, value as string)
    }

    const uploadResp = await new Promise((resolve, reject) => {
      xhr.upload.onprogress = (event) => {
        send({
          type: 'uploadProgressed',
          uploadId: upload.id,
          progress: { value: event.loaded, max: event.total }
        })
      }

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(undefined)
        } else {
          console.error('Upload failed', {
            status: xhr.status,
            statusText: xhr.statusText,
            response: xhr.responseText
          })
          send({ type: 'uploadFailed', uploadId: upload.id, error: 'network_error' })
          reject(
            new Error(
              `Upload failed with status: ${xhr.status} - ${xhr.statusText}: ${xhr.responseText}`
            )
          )
        }
      }

      xhr.onerror = (e) => {
        console.error('XHR onerror event triggered', e, {
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.responseText
        })

        send({ type: 'uploadFailed', uploadId: upload.id, error: 'network_error' })

        reject(new Error(`Network error during upload: ${xhr.status} - ${xhr.statusText}`))
      }

      xhr.send(file)
    })

    try {
      await sdk.send({
        type: 'folders/finalizeDocumentUpload',
        documentId: resp.documentId,
        documentTypeId: documentTypeId,
        questionnaireDocumentTypeId: questionnaireDocumentTypeId,
        folderId: folderId
      })
      send({ type: 'uploadCompleted', uploadId: upload.id })
    } catch (error) {
      send({ type: 'uploadFailed', uploadId: upload.id, error: 'server_error' })
    }

    return resp.documentId
  }
)

export function formatUploadError(error: UploadError) {
  switch (error) {
    case 'empty_file':
      return 'file is empty'
    case 'network_error':
      return 'network error'
    case 'server_error':
      return 'server error'
  }
}
